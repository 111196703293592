<template>
  <button class="btn i-btn" v-bind="$attrs" v-on="$listeners" :disabled="loading">
    <b-spinner v-if="loading" small></b-spinner>
    <slot v-else />
  </button>
</template>

<script>
export default {
  name: 'Btn',
  props: {
    loading: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss" scoped>
@import '../styles/index.scss';
.i-btn {
  height: 40px;
  width: 40px;
  font-weight: bold;
  font-size: 13px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-primary.i-btn {
  background-color: $primary;
  &:focus {
    box-shadow: rgba($color: $primary, $alpha: 0.8) 0 0 10px;
  }
}

.btn-outline-secondary.i-btn {
  color: #5c6c87;
  border: 1.5px solid #253043;
  background-color: rgba(37, 48, 67, 0.2);
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);

  &:hover:not(disabled) {
    outline: none;
    color: #eee;
    background-color: rgba(37, 48, 67, 0.2);
    border: 1.5px solid #888;
  }

  &:hover:disabled {
    color: #5c6c87;
    background-color: rgba(37, 48, 67, 0.2);
    border: 1.5px solid #253043;
  }

  /* &:focus {
    outline: none;
    color: #fff;
    background-color: rgba(37, 48, 67, 0.2);
    border: 1.5px solid #fff;
  } */
  &:not(:disabled):not(:disabled):active {
    outline: none;
    color: #fff;
    background-color: rgba(37, 48, 67, 0.2);
    border: 1.5px solid #fff;
  }
}
</style>
