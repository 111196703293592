<template>
  <b-form-group v-if="user.isLogin" label-cols-sm="3" label="推广码" label-align-sm="left" label-for="otp-ref">
    <b-input-group>
      <b-form-input
        :disabled="generating"
        placeholder="请输入推广码"
        id="otp-ref"
        maxlength=""
        :min="3"
        :max="200"
        v-model="parentString"
        ref="parentString"
      />
      <b-input-group-append>
        <b-button @click="updateRef" class="ml-2" variant="primary" :disabled="generating || disable">
          更新
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
  <div v-else class="login-alert">
    请先登录
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { UserService } from '@/services/api'

export default {
  name: 'RefSetting',
  props: {
    disable: { type: Boolean, default: false },
    generating: { type: Boolean, default: false },
  },
  methods: {
    async updateRef() {
      const newRef = this.$refs.parentString.value
      try {
        await UserService.updateParent(newRef)
        this.$bvToast.toast('推广码修改成功', {
          title: '更新成功，推广码修改成功',
          toaster: 'b-toaster-bottom-right',
          variant: 'success',
          solid: true,
        })
      } catch (e) {
        this.$bvToast.toast('请确认推广码是否正确', {
          title: '更新失败，请确认推广码是否正确',
          toaster: 'b-toaster-bottom-right',
          variant: 'warning',
          solid: true,
        })
      }
    },
  },
  data() {
    return {
      parentString: '',
    }
  },
  mounted() {
    // this.$store.dispatch('LOAD_USER')
    this.parentString = this.$store.state.user.info.parentString
  },
  computed: {
    ...mapGetters([
      'user',
      // ...
    ]),
  },
}
</script>
<style lang="scss" scoped>
.update-ref {
  padding: 1em;
}
.btn-update-ref {
  word-break: keep-all;
  margin-left: 1em;
}
.login-alert {
  padding: 10px;
  text-align: center;
  font-size: 20px;
  background: rgba(102, 102, 102, 0.2);
  border-radius: 5px;
}
</style>
