<template>
  <button
    class="btn i-btn"
    v-bind="$attrs"
    v-on="$listeners"
    :class="[{ 'i-btn--rounded': rounded }, `i-btn--${size}`]"
  >
    <div class="btn-wrapper">
      <b-spinner v-if="loading" small></b-spinner>
      <slot />
    </div>
  </button>
</template>

<script>
export default {
  name: 'Btn',
  props: {
    loading: { type: Boolean, default: false },
    rounded: { type: Boolean, default: false },
    size: { type: String, default: 'md' },
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/index.scss';
.i-btn {
  height: 56px;
  padding: 2px;
  font-size: 14px;
  color: #fff;
  border-radius: 8px;
  outline: none;
  border: none;
  position: relative;

  .btn-wrapper {
    background: #090d16;
    width: 100%;
    height: 100%;
    padding: 14px 28px;
    line-height: 20px;
    margin: 0;
    border-radius: 7px;
    margin: auto;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: '';
      background: transparent;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      transition: all 0.2s ease;
      border-radius: 7px;
      z-index: -1;
    }
    &:focus {
    }
  }
}

.i-btn.i-btn--sm {
  height: 40px;
}

.i-btn.i-btn--rounded {
  border-radius: 20px;

  .btn-wrapper {
    border-radius: 19px;
    &::after {
      border-radius: 19px;
    }
  }
}

.btn-primary.i-btn {
  background: linear-gradient(to right, #ded221 0%, #32d229 100%);
  .btn-wrapper {
    background: transparent;
  }
  &:hover {
    box-shadow: 0 0 6px #32d229;
  }
  &:focus {
    box-shadow: 0 0 8px #32d229;
  }
}

.btn-outline-primary.i-btn {
  background: linear-gradient(to right, #ded221 0%, #32d229 100%);
  &:hover {
  }
  &:focus {
    box-shadow: 0 0 15px #32d229;
  }
  &:active {
    box-shadow: 0 0 15px #32d229;
  }
  .btn-wrapper:hover::after {
    background: rgba(113, 215, 79, 0.4);
  }
}

$active: #b8b8b888;
.btn-outline-secondary.i-btn {
  color: #ddd;
  border: 2px solid #253043;
  background-color: rgba(37, 48, 67, 0.2);
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);

  &:hover {
    outline: none;
    color: #fff;
    background-color: rgba(37, 48, 67, 0.2);
    border-color: $active;
  }

  &:focus {
    outline: none;
    color: #fff;
    background-color: rgba(37, 48, 67, 0.2);
    border-color: $active;
  }
  &:not(:disabled):not(:disabled):active {
    outline: none;
    color: #fff;
    background-color: rgba(37, 48, 67, 0.2);
    border-color: $active;
  }
}
</style>
