<template>
  <span
    class="loader"
    :style="{
      width: `${width}px`,
      height: `${width}px`,
      borderWidth: `${border}px`,
    }"
  ></span>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    width: { type: String, default: '80' },
  },
  computed: {
    border() {
      return parseFloat(this.width) * 0.2
    },
  },
}
</script>

<style lang="scss" scoped>
.loader {
  /* width: 48px;
  height: 48px; */
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
