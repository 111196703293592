export const LOAD_USER = 'LOAD_USER'
export const SET_USER = 'SET_USER'

export const SET_NOT_LOGIN = 'SET_NOT_LOGIN'
export const SET_LOGIN = 'SET_LOGIN'

export const SET_ERROR = 'SET_ERROR'
export const SET_MODAL = 'SET_MODAL'

export const LOAD_NUM_OFFER = 'LOAD_NUM_OFFER'

export const SET_NUM_OFFER = 'SET_NUM_OFFER'
