export const BASE_URL = process.env.VUE_APP_ENDPOINT

export const ASSET_URL = 'https://steamcommunity-a.akamaihd.net/economy/image/'

export const API_URL = `${BASE_URL}/api`
export const STEAM_LOGIN_URL = `${BASE_URL}/steam/auth`

export const WEB_URL = process.env.VUE_APP_DOMAIN
  ? process.env.VUE_APP_DOMAIN
  : 'https://www.skinskitty.com'

export default API_URL
