<template>
  <div class="chip">
    <div class="label">{{ label }}：</div>
    <div class="chip-wrapper overflow-x-auto" ref="slideChipWrapper">
      <ul class="slide-chip-list w-full list-none" ref="slideChipList">
        <li
          class="slide-chip"
          v-for="tab in options"
          :key="tab.value"
          :class="{ 'slide-chip--active': isActive(tab.value) }"
          @click="iVal = tab"
          :ref="`slideChip${tab.value}`"
        >
          <slot :name="tab.value" :item="tab" />

          <span v-if="!$scopedSlots[tab.value]">{{ tab.label }}</span>
        </li>
        <div class="slide-bar" ref="slideBar" :style="{ left: `${barLeftOffset}px`, width: `${barWidth}px` }"></div>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlideChipSelect',
  props: {
    value: Object,
    options: {
      type: Array,
    },
    label: String,
  },
  data() {
    return {
      iVal: this.value,
      barLeftOffset: 0,
      barWidth: 55,
    }
  },
  computed: {},
  mounted() {
    this.adjustBar(this.value)
  },
  watch: {
    value(val) {
      this.iVal = val
    },
    iVal(val) {
      this.$emit('input', val)
      this.adjustBar(val)
    },
  },
  methods: {
    isActive(value) {
      return this.iVal?.value === value
    },
    adjustBar(val) {
      // const bar = this.$refs.slideBar.getBoundingClientRect()
      const listOffset = this.$refs.slideChipList.getBoundingClientRect()
      const selectedOffset = this.$refs[`slideChip${val.value}`][0].getBoundingClientRect()
      this.barLeftOffset = selectedOffset.left - listOffset.left
      this.barWidth = selectedOffset.width
    },
  },
}
</script>

<style lang="scss" scoped>
.chip {
  min-width: 140px;
  height: 56px;
  padding: 2px;
  box-sizing: border-box;
  border-radius: 35px;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;

  .label {
    min-width: 100px;
    flex-shrink: 0;
    line-height: 52px;
    font-size: 16px;
    text-align: left;
  }

  .chip-wrapper {
    width: 100%;
    height: 100%;
    padding: 0 12px;
    margin: 0;
    border-radius: 35px;
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    user-select: none;

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }
  }
}

.slide-chip-list {
  margin: 0;
  padding: 0;
  display: flex;
  font-size: 14px;
  position: relative;

  .slide-chip {
    padding: 10px 20px;
    text-decoration: none;
    z-index: 1;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    color: #bbb;
    flex-shrink: 0;

    &:hover {
      color: #fff;
    }
  }

  .slide-chip.slide-chip--active {
    color: #fff;
  }

  .slide-bar {
    height: 38px;
    width: 60px;
    background: #374151;
    position: absolute;
    top: 0;
    border-radius: 28px;
    z-index: 0;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
}
</style>
