<template>
  <div class="divider">
    <div :class="{ dashed }"></div>
  </div>
</template>

<script>
export default {
  name: 'Divider',
  props: {
    dashed: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.divider {
  width: 100%;
  height: 0;
  padding: 16px 0;
  div {
    width: 100%;
    height: 0;
    border-bottom: 1px solid #253043;
    &.dashed {
      border-bottom-style: dashed;
    }
  }
}
</style>
