<template>
  <div class="chip2" @click="$emit('click')">
    <svg
      v-if="!active"
      class="chip2__bg"
      width="80"
      height="32"
      viewBox="0 0 80 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_897_10408" fill="white">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10 0H26H54H60L79.3586 21.2945C79.7177 21.6894 79.7032 22.2968 79.3258 22.6742L70 32H54H26H20L0.641409 10.7055C0.282341 10.3106 0.296794 9.70321 0.674242 9.32576L10 0Z"
        />
      </mask>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 0H26H54H60L79.3586 21.2945C79.7177 21.6894 79.7032 22.2968 79.3258 22.6742L70 32H54H26H20L0.641409 10.7055C0.282341 10.3106 0.296794 9.70321 0.674242 9.32576L10 0Z"
        fill="#253043"
        fill-opacity="0.2"
      />
      <path
        d="M10 0L9.2929 -0.707107L9.58579 -1H10V0ZM60 0V-1H60.4424L60.7399 -0.672673L60 0ZM79.3586 21.2945L78.6187 21.9671L78.6187 21.9671L79.3586 21.2945ZM79.3258 22.6742L80.0329 23.3813L80.0329 23.3813L79.3258 22.6742ZM70 32L70.7071 32.7071L70.4142 33H70V32ZM20 32V33H19.5576L19.2601 32.6727L20 32ZM0.641409 10.7055L1.38135 10.0329L1.38135 10.0329L0.641409 10.7055ZM0.674242 9.32576L-0.0328651 8.61866L-0.0328645 8.61866L0.674242 9.32576ZM26 1H10V-1H26V1ZM54 1H26V-1H54V1ZM54 -1H60V1H54V-1ZM60.7399 -0.672673L80.0985 20.6218L78.6187 21.9671L59.2601 0.672673L60.7399 -0.672673ZM80.0985 20.6218C80.8167 21.4117 80.7878 22.6264 80.0329 23.3813L78.6187 21.9671L78.6187 21.9671L80.0985 20.6218ZM80.0329 23.3813L70.7071 32.7071L69.2929 31.2929L78.6187 21.9671L80.0329 23.3813ZM70 33H54V31H70V33ZM26 31H54V33H26V31ZM20 31H26V33H20V31ZM1.38135 10.0329L20.7399 31.3273L19.2601 32.6727L-0.0985308 11.3782L1.38135 10.0329ZM1.38135 10.0329L1.38135 10.0329L-0.0985301 11.3782C-0.816667 10.5883 -0.787761 9.37355 -0.0328651 8.61866L1.38135 10.0329ZM10.7071 0.707107L1.38135 10.0329L-0.0328645 8.61866L9.2929 -0.707107L10.7071 0.707107Z"
        fill="#253043"
        mask="url(#path-1-inside-1_897_10408)"
      />
    </svg>

    <svg
      v-else
      class="chip2__bg"
      width="80"
      height="32"
      viewBox="0 0 80 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_ii_897_10360)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.76184 0H26H54H60L79.3586 21.2945C79.7177 21.6894 79.7032 22.2968 79.3258 22.6742L70 32H54H26H19.9107L0.263933 10.7055C-0.10048 10.3106 -0.0858123 9.70321 0.297255 9.32576L9.76184 0Z"
          fill="#71D74F"
          fill-opacity="0.2"
        />
        <path
          d="M9.17158 0H13.8284L3.75121 10.0772L23.3418 32H18.9774L0.508692 11.3327C-0.198276 10.5415 -0.16444 9.33602 0.585791 8.58578L9.17158 0Z"
          fill="#32D229"
        />
        <path
          d="M70.8284 32H66.1716L76.2488 21.9228L56.6582 0H61.0226L79.4913 20.6673C80.1983 21.4585 80.1644 22.664 79.4142 23.4142L70.8284 32Z"
          fill="#32D229"
        />
      </g>
      <defs>
        <filter
          id="filter0_ii_897_10360"
          x="-2"
          y="-2"
          width="82"
          height="34"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.443137 0 0 0 0 0.843137 0 0 0 0 0.309804 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_897_10360" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.443137 0 0 0 0 0.843137 0 0 0 0 0.309804 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="effect1_innerShadow_897_10360" result="effect2_innerShadow_897_10360" />
        </filter>
      </defs>
    </svg>

    <span>
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  name: 'Chip2',
  props: {
    active: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss" scoped>
.chip2 {
  position: relative;
  min-width: 80px;
  height: 32px;
  color: #7b8ba7;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chip2__bg {
  position: absolute;
}
</style>
