<template>
  <div class="collection-card">
    <router-link :to="to">
      <div class="wrapper">
        <div class="img" :style="{ backgroundImage: imgBg }"></div>
        <div class="collection-card__details">
          <h5 class="text-11">{{ item.title }}</h5>
          <div class="d-flex justify-content-between align-items-center text-9">
            <avatar :imgUrl="avatarUrl" />
            <span class="ml-2">{{ item.name }}</span>
            <!-- <span class="ml-auto">{{ num }}</span>
            <heart-svg class="ml-2 heart" @click.stop.prevent.native="clickLike" /> -->
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'CollectionCard',
  props: {
    item: Object,
    to: { type: String, default: '' },
  },
  data() {
    return {
      num: 23,
    }
  },
  computed: {
    imgBg() {
      const img = this.item.image_url
      if (img === 'dota2') {
        return `url(${require('@/assets/dota2-bg.png')})`
      }
      if (img === 'csgo') {
        return `url(${require('@/assets/csgo-bg.png')})`
      }
      return `url(${this.item.image_url})`
    },
    avatarUrl() {
      const img = this.item.avatar_url
      if (img === 'dota2') {
        return require('@/assets/dota2-avatar.svg')
      }
      if (img === 'csgo') {
        return require('@/assets/csgo-avatar.svg')
      }
      return img
    },
  },
  methods: {
    clickLike() {
      this.num++
    },
  },
}
</script>

<style lang="scss" scoped>
.collection-card {
  height: 340px;
  padding: 10px;

  .wrapper {
    width: 100%;
    height: 100%;
    border-radius: 24px;
    overflow: hidden;
    position: relative;
    transition: all 0.5s ease;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.1);

    &:hover {
      box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.25);
      .img {
        transform: scale(1.1);
      }
      .collection-card__details {
        background: #1f2430;
      }
    }
  }

  .img {
    width: 100%;
    height: 210px;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 0.5s ease;
  }
}

.collection-card__details {
  width: 100%;
  height: 110px;
  padding: 20px 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #141a24;
  color: #fff;
  user-select: none;
  transition: all 0.5s ease;
}

.heart {
  cursor: pointer;
  fill: #6b7280;
  &:hover {
    fill: rgb(255, 73, 82);
  }
}
</style>
