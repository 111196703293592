var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"dropdown",staticClass:"dropdown",class:{
    'dropdown--disabled': _vm.disabled,
    'dropdown--primary': _vm.primary,
    'dropdown--borderless': _vm.borderless,
  },attrs:{"tabindex":"0"},on:{"blur":_vm.close}},[_c('div',{staticClass:"dropdown__title",on:{"click":_vm.toggle}},[(!_vm.$scopedSlots['title'])?[(_vm.title)?_c('span',[_vm._v(_vm._s(_vm.title))]):_c('span',[_vm._v(_vm._s(_vm.selectedLabel))])]:_vm._t("title",null,{"selected":_vm.selected}),_c('fa',{staticClass:"dropdown-arrow",class:{ flip: _vm.show },attrs:{"icon":['fas', 'chevron-down']}})],2),_c('div',{style:(("display:" + (_vm.show ? 'block' : 'none') + ";animation:" + (_vm.show ? 'rotateMenu 0.5s;' : 'none')))},[_c('ul',{key:"menu",ref:"menu",staticClass:"dropdown__menu",class:{ tooLong: _vm.tooLong },style:(("left:-" + _vm.offsetRight + "px;"))},_vm._l((_vm.data),function(ref,k){
  var label = ref.label;
  var value = ref.value;
return _c('li',{key:k,staticClass:"item",class:{ active: value === _vm.selected.value },on:{"click":function($event){return _vm.select({ label: label, value: value })}}},[_vm._t(value),_c('span',[_vm._v(_vm._s(label))]),(value === _vm.selected.value)?_c('span',{staticClass:"ml-auto",staticStyle:{"position":"absolute","right":"10px"}},[_c('tickbare-svg')],1):_vm._e()],2)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }