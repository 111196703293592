<template>
  <div style="position: relative;">
    <div class="currency-sign">
      <eth-svg fill="#fff" width="12" v-if="iValue === 'eth'" />
      <span v-if="iValue === 'usd'" style="line-height:22px">$</span>
    </div>
    <select v-model="iValue" class="i-select" name="select" id="" v-bind="$attrs" @blur="onBlur">
      <option v-for="(o, k) in options" :key="k" :value="o.value">
        <span v-if="!$scopedSlots[o.value]">{{ o.label }}</span>
        <slot v-else :name="o.value" :value="o.value" />
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'Input',
  props: {
    value: [String, Number],
    options: { type: Array },
  },
  data() {
    return {
      iValue: '',
    }
  },
  computed: {},
  created() {
    if (this.value) {
      this.iValue = this.value
    }
  },
  watch: {
    value(val) {
      this.iValue = val
    },
    iValue(val) {
      this.$emit('input', val)
    },
  },
  methods: {
    onBlur(e) {
      this.$emit('blur', e)
    },
  },
}
</script>

<style lang="scss" scoped>
.i-select {
  width: 100%;
  height: 48px;
  padding-left: 32px;
  background: transparent;
  color: #fff;
  border: 1px solid #253043;
  border-radius: 6px;

  &:focus {
    border-color: #3a475f;
  }

  &:focus-visible {
    border-color: #3a475f;
    outline: none;
  }

  option {
    background: transparent;
    color: #253043;
  }
}

.currency-sign {
  position: absolute;
  left: 14px;
  top: 13px;
}
</style>
