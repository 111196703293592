<template>
  <nav class="navbar-wrapper">
    <b-navbar class="navbar-inner mx-auto" style="max-width:1800px" toggleable="lg" type="dark" variant="">
      <!-- <b-navbar-brand href="/">
        <img src="@/assets/logo.svg" alt="" />
      </b-navbar-brand> -->
      <router-link to="/">
        <img class="logo" src="@/assets/logo.svg" alt="" />
        <img class="logo-sm" src="@/assets/logo-sm.svg" alt="" />
      </router-link>
      <div class="px-2 md:px-5 flex-1" style="flex:1 0 200px">
        <search-global />
      </div>
      <b-navbar-toggle target="nav-collapse" />

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item v-for="item in items" :key="item.name" href="#">
            <router-link :to="item.link" style="text-decoration:none">
              <span class="text-decoration-none nav-span" :class="{ 'nav-item--active': isActiveRoute(item.link) }">
                {{ item.name }}
                <b-badge
                  v-if="item.link === '/offer' && numOfOffers"
                  pill
                  class="ml-1 px-2"
                  variant="light"
                  style="display:inline"
                >
                  {{ numOfOffers }}
                </b-badge>
              </span>
            </router-link>
          </b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-1 align-items-center">
          <lang-select />

          <div v-if="user.isLogin" class="d-flex align-items-center">
            <div v-if="user.info.avatar_url" class="topup pointer" @click="$bvModal.show('topup')">
              <span class="text-white mr-auto">{{ $currencySign }}</span>
              <span class="text-white px-2">{{ balance }}</span>
              <fa class="text-secondary" :icon="['fas', 'plus']" />
            </div>

            <b-dropdown variant="outline" class="user-info" right no-caret>
              <template v-slot:button-content>
                <div class="">
                  <b-avatar :src="user.info.avatar_url" rounded />
                </div>
              </template>

              <b-dropdown-item>
                <router-link to="/user" style="text-decoration:none">
                  <div class="d-flex align-items-center">
                    <user-svg width="24" />
                    <div class="ml-3 pointer">
                      {{ $t('navbar.my-account') }}
                    </div>
                  </div>
                </router-link>
              </b-dropdown-item>

              <b-dropdown-item v-if="isMerchant">
                <router-link to="/dashboard" style="text-decoration:none">
                  <div class="d-flex align-items-center">
                    <briefcase-svg width="24" class="text-white" />
                    <div class="ml-3 pointer">
                      {{ $t('navbar.merchant') }}
                    </div>
                  </div>
                </router-link>
              </b-dropdown-item>

              <b-dropdown-item v-if="isMerchant">
                <router-link to="/merchant/settings" style="text-decoration:none">
                  <div class="d-flex align-items-center">
                    <adjustments-svg width="24" class="text-white" />
                    <div class="ml-3 pointer">
                      {{ $t('navbar.merchant-settings') }}
                    </div>
                  </div>
                </router-link>
              </b-dropdown-item>

              <b-dropdown-item v-if="!wallet.address">
                <router-link to="/user/profile?tab=wallet" style="text-decoration:none">
                  <div class="d-flex align-items-center">
                    <metamask-svg width="24" />
                    <div class="ml-3 pointer">
                      {{ $t('navbar.metamask') }}
                    </div>
                  </div>
                </router-link>
              </b-dropdown-item>

              <b-dropdown-item v-if="wallet.address">
                <div class="text-white">
                  <div class="d-flex align-items-center">
                    <metamask-svg width="24" />
                    <div class="ml-3 pointer">
                      <div>Ethereum</div>
                      <div class="ellipsis" style="width:100px">{{ wallet.address }}</div>
                    </div>
                  </div>
                  <div class="mt-3 mb-1 p-3" style="border:1px solid #4B5563;border-radius: 12px;">
                    <eth-svg width="14" />
                    <span class="ml-3">{{ wallet.balance }}</span>
                  </div>
                </div>
              </b-dropdown-item>

              <!-- <b-dropdown-item>
                <router-link to="/page/help" style="text-decoration:none">
                  <div class="d-flex align-items-center">
                    <question-svg width="24" height="24" />
                    <div class="ml-3 pointer">
                      {{ $t('navbar.help-center') }}
                    </div>
                  </div>
                </router-link>
              </b-dropdown-item> -->

              <b-dropdown-item @click="exit">
                <div class="d-flex align-items-center text-danger">
                  <logout-svg width="24" height="24" />
                  <div class="ml-3 pointer">
                    {{ $t('navbar.exit') }}
                  </div>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <!-- <button v-if="!user.isLogin" class="btn steam__login" @click="login">
            <fa :icon="['fab', 'steam']" />
            {{ $t('navbar.steam-login') }}
          </button> -->
          <btn-color v-if="!user.isLogin && !userLoading" class="btn btn-outline-primary ml-3" @click="login">
            {{ $t('navbar.login') }}
          </btn-color>

          <div v-if="!user.isLogin && userLoading" class="d-flex">
            <b-skeleton width="90px" height="40px" style="border-radius:3px" />
            <b-skeleton width="40px" height="40px" style="border-radius:3px" />
          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import { destroyToken, getToken } from '@/services/jwt'
import { STEAM_LOGIN_URL } from '@/services/config'
import LangSelect from './LangSelect.vue'
import SearchGlobal from './new/SearchGlobal.vue' 

export default {
  name: 'Navbar',
  components: {
    LangSelect,
    SearchGlobal,
  },
  data() {
    return {
      items: [
        { name: this.$t('navbar.store'), link: '/store' },
        // { name: this.$t('navbar.market'), link: '/market' },
        { name: this.$t('navbar.offer'), link: '/offer' },
        { name: this.$t('navbar.inventory'), link: '/user/inventories' },
        // { name: this.$t('navbar.help'), link: '/page/help' },
      ],
      scrollPosition: null,
      activeNav: this.$router.currentRoute, 
    }
  },
  computed: {
    ...mapGetters(['user', 'userLoading', 'numOfOffers', 'wallet']),
    balance() {
      if (!this.user.info || !this.user.info.balance) return 0
      return parseFloat(this.user.info.balance).toFixed(2)
    },
    isMerchant() {
      if (!this.user.info) return false
      return this.user.info.is_merchant
    },
  },
  watch: {
    $route(to) {
      this.activeNav = to
    },
  },
  mounted() {
    if (this.user.isLogin || getToken()) {
      this.$store.dispatch('LOAD_USER')
    }
    this.pollData()
    window.addEventListener('scroll', this.updateScroll)
  },
  beforeDestroy() {
    clearInterval(this.polling)
  },
  methods: {
    pollData() {
      this.polling = setInterval(() => this.user.isLogin && this.$store.dispatch('LOAD_USER'), 10000)
    },
    exit() {
      destroyToken()
      this.$store.commit('SET_NOT_LOGIN')
    },
    login() {
      window.location = STEAM_LOGIN_URL
    },
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
    isActiveRoute(link) {
      if (link === this.activeNav.path) return true
      if (link === '/offer' && (this.activeNav.name === 'NewOffer' || this.activeNav.name === 'OfferDetail'))
        return true
      if (link === '/store' && this.activeNav.name === 'StoreDetail') return true
      if (link === '/user/inventories' && this.activeNav.name === 'InventoryDetail') return true
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../styles/style.scss';

.navbar-wrapper {
  width: 100%;
  background-color: #090d16;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #606c8555;
}
.navbar-inner {
  width: 100%;
  background-color: #090d16;
  flex-wrap: nowrap;
}

.logo {
  @media screen and (max-width: 800px) {
    display: none;
  }
}

.logo-sm {
  @media screen and (min-width: 800px) {
    display: none;
  }
}

.navbar-collapse {
  justify-content: flex-end;
}

.nav-item--active::after {
  content: '';
  width: 100%;
  height: 3px;
  background: #32d229;
  position: absolute;
  top: -17px;
  left: 0;
}

.nav-span {
  padding: 0.9rem 0.6rem;
  background-size: 0 2px, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all 0.2s ease-out;
  position: relative;
  font-size: 14px;
  color: white;

  &:hover {
    color: $primary;
  }
}

.nav-link {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.nav-item {
  padding: 0.9rem 0.1rem;
  background-size: 0 2px, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all 0.2s ease-out;
  position: relative;
  font-size: 14px;

  &--active {
    &::after {
      content: '';
      width: 100%;
      height: 2px;
      background: #32d229;
      position: absolute;
      bottom: -17px;
      left: 0;
    }
  }
}

.steam__login {
  transition: all ease 0.4s;
  background: $primary-gradient;
  border-radius: 12px 2px 12px 2px;
  font-size: 16px;
  border: none;
  color: #fff;
  padding: 0.7em 1em;
  &:hover {
    box-shadow: 0 0 15px #32d229;
    color: #fff;
  }
}

.topup {
  min-width: 90px;
  height: 40px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #253043;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px 7px;
  background: rgba(37, 48, 67, 0.2);
  transition: all 0.3s ease;
  font-size: 13px;

  &:hover {
    background: rgba(37, 48, 67, 0.4);
  }
}

.user-info {
  .dropdown-toggle {
    background: transparent;
  }
}
</style>

<style lang="scss">
.user-info .dropdown-menu {
  padding: 0.8rem;
  min-width: 260px;
  border-radius: 6px;

  .dropdown-item {
    padding: 0.5rem;
    font-weight: bold;
  }
}
</style>
