<template>
  <div class="tabs pointer" :class="[`tabs--${size}`, { 'tabs--border': withBorder }]">
    <div
      @click="switchTab(tab)"
      :class="[{ active: activeType.value === tab.value }]"
      class="tab"
      v-for="tab in options"
      :key="tab.value"
    >
      <slot :name="tab.value" :item="tab" />

      <span v-if="!$scopedSlots[tab.value]">{{ tab.label }}</span>
    </div>

    <divider v-if="withBorder" style="position:absolute;bottom:0;padding:0" />
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    value: Object,
    options: Array,
    size: { type: String, default: 'lg' },
    withBorder: Boolean,
  },
  data() {
    return {
      activeType: this.options[0],
    }
  },
  watch: {
    value(val) {
      this.activeType = val
    },
    activeType(val) {
      this.$emit('input', val)
    },
  },
  methods: {
    switchTab(val) {
      this.activeType = val
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../styles/style.scss';

.tabs {
  display: flex;
  border: none;
  position: relative;

  &.tabs--border {
    width: 100%;
  }

  &.tabs--md {
    .tab {
      font-size: 16px;
      padding: 0 40px;
      min-height: 40px;
      line-height: 40px;
    }
  }

  &.tabs--sm {
    .tab {
      font-size: 14px;
      padding: 0 20px;
      min-height: 40px;
      line-height: 40px;
    }
  }

  .tab {
    min-height: 40px;
    position: relative;
    padding: 0.5em 1em;
    font-size: 18px;
    color: #999;

    &.active {
      color: #fff;
      &::after {
        content: '';
        height: 2px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(90deg, #ded221 0%, #32d229 100%);
      }
    }
  }
}
</style>
