<template>
  <div class="loader loader--small">
    <div class="loader__inner">
      <svg viewBox="0 0 126 126" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <symbol id="loader__circle"><circle r="10" cx="20" cy="20"></circle></symbol>
        <g class="loader__circles">
          <g class="loader__circle"><use xlink:href="#loader__circle" class="u--circle"></use></g>
          <g class="loader__circle"><use xlink:href="#loader__circle" class="u--circle"></use></g>
          <g class="loader__circle"><use xlink:href="#loader__circle" class="u--circle"></use></g>
          <g class="loader__circle"><use xlink:href="#loader__circle" class="u--circle"></use></g>
          <g class="loader__circle"><use xlink:href="#loader__circle" class="u--circle"></use></g>
          <g class="loader__circle"><use xlink:href="#loader__circle" class="u--circle"></use></g>
          <g class="loader__circle"><use xlink:href="#loader__circle" class="u--circle"></use></g>
          <g class="loader__circle"><use xlink:href="#loader__circle" class="u--circle"></use></g>
          <g class="loader__circle"><use xlink:href="#loader__circle" class="u--circle"></use></g>
          <g class="loader__circle"><use xlink:href="#loader__circle" class="u--circle"></use></g>
          <g class="loader__circle"><use xlink:href="#loader__circle" class="u--circle"></use></g>
          <g class="loader__circle"><use xlink:href="#loader__circle" class="u--circle"></use></g>
        </g>
      </svg>
      <div class="loader__logo">
        <!-- <img src="/static/media/logo-min.e8319599.svg" class="loader__logo-img" alt="" /> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
}
</script>

<style lang="scss" scoped>
.loader {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
}

@media (max-width: 1200px) {
  .loader {
    padding: 80px 0;
  }
}

@media (max-width: 900px) {
  .loader {
    padding: 50px 0;
  }
}

@media (max-width: 599px) {
  .loader {
    padding: 30px 0;
  }
}

.loader--small {
  margin: 0;
}

.loader--upgrade {
  align-items: center;
  height: 395px;
  margin: 0;
}

.loader--no-margin {
  margin: 0;
}

.loader__inner {
  position: relative;
  width: 130px;
  height: 130px;
  text-align: center;
  background-color: rgba(20, 20, 28, 0.85);
  border-radius: 100%;
}

@media (max-width: 900px) {
  .loader__inner {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 599px) {
  .loader__inner {
    width: 80px;
    height: 80px;
  }
}

.loader svg {
  width: 126px;
  height: 126px;
  margin: 2px;
  overflow: visible;
}

@media (max-width: 900px) {
  .loader svg {
    width: 96px;
    height: 96px;
  }
}

@media (max-width: 599px) {
  .loader svg {
    width: 76px;
    height: 76px;
  }
}

.loader__circle {
  transform-origin: 63px 63px;
  animation: opacity 1.2s linear infinite;
  fill: #fcac62;
  fill-opacity: 0;
}

.loader__circle:nth-child(12n + 1) {
  transform: rotate(-30deg) translate(5px, 5px) scale(0.9);
  animation-delay: -0.1s;
}

.loader__circle:nth-child(12n + 2) {
  transform: rotate(-60deg) translate(5px, 5px) scale(0.9);
  animation-delay: -0.2s;
}

.loader__circle:nth-child(12n + 3) {
  transform: rotate(-90deg) translate(5px, 5px) scale(0.9);
  animation-delay: -0.3s;
}

.loader__circle:nth-child(12n + 4) {
  transform: rotate(-120deg) translate(5px, 5px) scale(0.9);
  animation-delay: -0.4s;
}

.loader__circle:nth-child(12n + 5) {
  transform: rotate(-150deg) translate(5px, 5px) scale(0.9);
  animation-delay: -0.5s;
}

.loader__circle:nth-child(12n + 6) {
  transform: rotate(-180deg) translate(5px, 5px) scale(0.9);
  animation-delay: -0.6s;
}

.loader__circle:nth-child(12n + 7) {
  transform: rotate(-210deg) translate(5px, 5px) scale(0.9);
  animation-delay: -0.7s;
}

.loader__circle:nth-child(12n + 8) {
  transform: rotate(-240deg) translate(5px, 5px) scale(0.9);
  animation-delay: -0.8s;
}

.loader__circle:nth-child(12n + 9) {
  transform: rotate(-270deg) translate(5px, 5px) scale(0.9);
  animation-delay: -0.9s;
}

.loader__circle:nth-child(12n + 10) {
  transform: rotate(-300deg) translate(5px, 5px) scale(0.9);
  animation-delay: -1s;
}

.loader__circle:nth-child(12n + 11) {
  transform: rotate(-330deg) translate(5px, 5px) scale(0.9);
  animation-delay: -1.1s;
}

.loader__circle:nth-child(12n + 12) {
  transform: rotate(-1turn) translate(5px, 5px) scale(0.9);
  animation-delay: -1.2s;
}

.loader__logo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader__logo-img {
  width: 50px;
}

@media (max-width: 900px) {
  .loader__logo-img {
    width: 35px;
  }
}

@media (max-width: 599px) {
  .loader__logo-img {
    width: 30px;
  }
}
</style>
