<template>
  <div class="search-input" :style="`width:${width}`">
    <input
      v-model="keyword"
      class="keyword"
      :style="hideIcon ? '' : 'padding-right:32px'"
      type="text"
      required
      :placeholder="placeholder"
      :disabled="readOnly"
      :class="noRightBorder ? 'no-right-border' : ''"
      @blur="onBlur"
      @focus="onFocus"
      @keypress="onKeydown"
    />
    <magnifier-svg class="search-icon" v-if="!hideIcon" />
    <b-spinner v-if="loading" class="search-input__spinner" variant="secondary" small />
    <cross-svg
      v-if="keyword"
      class="absolute cursor-pointer text-white"
      width="18"
      style="transform:translateY(-50%);right:16px;top:50%"
      @click.native="clear"
    />
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  props: {
    placeholder: String,
    value: String,
    width: { type: String, default: '300px' },
    readOnly: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    hideIcon: { type: Boolean, default: false },
    noRightBorder: { type: Boolean, default: false },
    autoUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      keyword: this.value,
      autoUpdateInterval: null,
      // eventBus: new Vue(),
    }
  },
  mounted() {
    setTimeout(() => {
      this.$watch('value', this.valueWatcher)
    }, 1000)
  },
  watch: {
    value(val) {
      this.keyword = val
    },
    keyword(val) {
      this.$emit('input', val)
    },
  },
  methods: {
    valueWatcher(val) {
      this.keyword = val
      clearInterval(this.autoUpdateInterval)
      // this.eventBus.$emit('stop')
      if (this.autoUpdate && val && val.length >= 2) {
        // this.eventBus.$emit('restart')
        this.autoUpdateInterval = setTimeout(() => {
          console.log('complete')
          this.$emit('complete')
        }, 1000)
      }
    },
    clear() {
      this.keyword = ''
    },
    onBlur(e) {
      this.$emit('blur', e)
    },
    onFocus(e) {
      this.$emit('focus', e)
    },
    onKeydown(e) {
      if (e.key === 'Enter') {
        this.$emit('enter', this.keyword)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$transit: all 0.15s ease-in-out;

.search-input {
  /* max-width: 750px;
  min-width: 300px; */
  display: flex;
  position: relative;
  height: 48px;
}

.search-icon {
  color: #ccc;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: $transit;
}

.dropdown {
  margin: 0;
}

.keyword {
  width: 100%;
  line-height: 40px;
  height: 48px;
  padding-left: 50px;
  background-color: transparent;
  border: 1px solid #253043;
  color: #7b8ba7;
  font-size: 0.9rem;
  transition: $transit;
  border-radius: 24px;

  &:hover,
  &:focus {
    border: 1px solid #5c6c87;
    background-color: rgba(92, 108, 135, 0.2);
    color: #5c6c87;
    box-shadow: none;
  }

  &:focus {
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.25);
  }

  &:focus-visible {
    outline: none;
  }

  &:hover + .search-icon,
  &:focus + .search-icon {
    color: #eee;
  }
}

.search-input__spinner {
  position: absolute;
  right: 10px;
  top: 10px;
}

.form-control.no-right-border {
  border-right: none;
  border-radius: 0.25rem 0 0 0.25rem;
}
</style>
