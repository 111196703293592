export default {
  home: state => state.home,
  app: state => state.app,
  user: state => state.user,
  userLoading: state => state.userLoading,
  dice: state => state.dice,
  error: state => state.error,
  modal: state => state.modal,
  numOfOffers: state => state.numOfOffers,
  allOffers: state => state.allOffers,
  recentReceivers: state => state.recentReceivers,
  wallet: state => state.wallet,
  lang: state => state.lang,
}
