<template>
  <div class="item" :class="`border--${item.rarity}`">
    <div class="item__image">
      <img :src="item.image_url" class="item__img " />
    </div>
    <div class="item__info">
      <div class="item__name">
        <div>{{ item.name }}</div>
      </div>
      <div class="item__status">来自Yesskins</div>
      <div class="price text-success">${{ item.price }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LongItem',
  props: {
    inventory: Object,
  },
  computed: {
    item() {
      return this.inventory.item
    },
  },
  methods: {
    emit(event) {
      this.$emit(event)
    },
  },
  data: function() {
    return {}
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../styles/style.scss';
.action {
  padding: 0.2em 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.price {
  font-size: 1.125em;
}
@each $rarity, $color in $rarities {
  .border--#{$rarity} {
    border-left: solid 2px $color;
  }
}
.item {
  cursor: pointer;
  user-select: none;
  display: flex;
  background-color: #12121e;
  align-items: center;
  justify-content: center;
  padding: 1em;
  &__name {
    display: flex;
    justify-content: space-between;
  }
  &__status {
    color: #a5a5a5;
    font-size: 0.7em;
  }
  &__info {
    color: #fff;
    font-size: 18px;
  }
  &__img {
    max-height: 90px;
    &:hover {
      animation: bounce 4s ease-in-out 1s infinite alternate;
    }
    -webkit-user-drag: none;
  }
  &__image {
    padding: 10px;
    height: 110px;
  }
  margin: 0.5em 1em;
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px) translateX(1px);
  }
  100% {
    transform: translateY(10px);
  }
}
</style>
