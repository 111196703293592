<template>
  <modal id="topup-real-name-modal" width="650px">
    <template #header>请进行支付宝身份认证</template>
    <template #body>
      <div class="pt-4 pb-3 px-8">
        <div>为保证您的账户资金安全，您需要先完善实名信息，通过支付宝身份认证后才可使用充值功能。</div>
        <div class="flex items-center justify-center mt-4">
          <btn class="btn-secondary" @click="$modal.hide('topup-real-name-modal')">取消</btn>
          <btn class="btn-primary ml-2" @click="goVerify">开始认证</btn>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
export default {
  name: 'TopupRealNameModal',
  methods: {
    goVerify() {
      this.$modal.hide('topup-real-name-modal')
      if (this.$router.currentRoute.path === '/user/profile') {
        this.$modal.show('alipay-verification')
      } else {
        this.$router.push('/user/profile?verify=1')
      }
    },
  },
}
</script>
