<template>
  <div
    class="dropdown"
    :class="{
      'dropdown--disabled': disabled,
    }"
    tabindex="0"
    @blur="close"
  >
    <div class="dropdown__title" @click="toggle">
      <span v-if="title">{{ title }}</span>
      <span v-else>{{ selectedLabel }}</span>
      <fa v-if="down" style="position:absolute;right:10px;font-size:10px" :icon="['fas', 'chevron-up']" />
      <fa v-else style="position:absolute;right:10px;font-size:10px" :icon="['fas', 'chevron-down']" />
    </div>

    <transition-group name="list" tag="div" class="dropdown-transition" :class="{ 'drop-up': dropUp }">
      <ul v-if="down" key="menu" class="dropdown__menu">
        <li
          class="item"
          v-for="({ label, value }, k) in data"
          :key="k"
          :class="{ active: selectIndex({ label, value }) > -1 }"
          @click="multiPick({ label, value })"
        >
          <div class="square"></div>
          <span>{{ label }}</span>
        </li>
      </ul>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'DropDown',
  props: {
    title: String,
    data: Array,
    dropUp: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: [Object, Array],
  },
  data() {
    return {
      down: false,
      selected: this.value,
    }
  },
  computed: {
    selectedLabel() {
      if (!this.selected || this.selected.length === 0) {
        return '-'
      }
      if (this.selected.length === this.data.length) {
        return '全部'
      }
      let str = ''
      for (let i = 0; i < this.selected.length; i++) {
        str += this.selected[i].label
        if (i !== this.selected.length - 1) {
          str += '，'
        }
      }
      return str
    },
  },
  watch: {
    value(val) {
      this.selected = val
    },
  },
  mounted() {},
  methods: {
    toggle() {
      if (this.disabled) {
        this.down = false
        return
      }
      this.down = !this.down
    },
    close() {
      this.down = false
    },
    multiPick(item) {
      const v = this.selected
      const index = this.selectIndex(item)
      if (index === -1) {
        v.push(item)
      } else {
        v.splice(index, 1)
      }
      this.$emit('input', v)
    },
    selectIndex(item) {
      for (let i = 0; i < this.selected.length; i++) {
        if (this.selected[i].value === item.value) return i
      }
      return -1
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../styles/style.scss';

.dropdown {
  min-width: 125px;
  height: 40px;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid #253043;
  border-radius: 4px;
  outline: none;
  position: relative;
  z-index: 100;

  &:focus {
    background-color: #25304399;
  }
  &:hover {
    background-color: #25304399;
  }
  &__title {
    width: 100%;
    height: 100%;
    padding: 0 15px;
    padding-right: 35px;
    color: rgba(255, 255, 255, 0.7);
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__menu {
    width: 100%;
    padding: 0;
    margin: 0;
    margin-top: 4px;
    color: rgba(255, 255, 255, 0.7);
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 0;
    background-color: #253043ee;
    border: 1px solid #253043;
    border-radius: 4px;
    list-style-type: none;
    cursor: pointer;
    overflow: hidden;
    position: absolute;

    .item {
      text-align: left;
      line-height: 40px;
      padding: 0 15px;
      user-select: none;
      display: flex;
      align-items: center;

      &:hover {
        background-color: rgba(33, 42, 57, 0.5);
      }
      &.active {
        color: #fff;
        .square {
          background-color: $primary;
        }
      }

      .square {
        width: 10px;
        height: 10px;
        margin-right: 10px;
        background-color: #0b0e18;
        border-radius: 2px;
      }
    }
    .close-btn {
      height: 50px;
      font-size: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.sort-icon {
  color: $primary;
}

.drop-up {
  width: 100%;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
}

.list-enter-active,
.list-leave-active,
.list-move {
  transition: all 0.05s ease-out;
  transform-origin: center top;
}
.drop-up {
  .list-enter-active,
  .list-leave-active,
  .list-move {
    transform-origin: center bottom;
  }
}
.list-enter {
  opacity: 0;
  transform: scaleY(0.5);
  height: 0px;
}
.list-enter-to {
  opacity: 1;
  transform: scaleY(1);
  height: 100%;
}
.list-leave-active {
}
.list-leave-to {
  opacity: 0;
  height: 0px;
  transform: scaleY(0);
}
</style>
