<template>
  <div
    class="dropdown"
    :class="{
      'dropdown--disabled': disabled,
      'dropdown--primary': primary,
      'dropdown--borderless': borderless,
    }"
    tabindex="0"
    @blur="close"
    ref="dropdown"
  >
    <div class="dropdown__title" @click="toggle">
      <template v-if="!$scopedSlots['title']">
        <span v-if="title">{{ title }}</span>
        <span v-else>{{ selectedLabel }}</span>
      </template>
      <slot v-else name="title" :selected="selected" />
      <fa class="dropdown-arrow" :class="{ flip: show }" :icon="['fas', 'chevron-down']" />
      <!-- <fa v-else :icon="['fas', 'chevron-down']" /> -->
    </div>

    <!-- <transition-group name="list" tag="div" class="dropdown-transition"> -->
    <div :style="`display:${show ? 'block' : 'none'};animation:${show ? 'rotateMenu 0.5s;' : 'none'}`">
      <ul key="menu" ref="menu" class="dropdown__menu" :class="{ tooLong }" :style="`left:-${offsetRight}px;`">
        <li
          v-for="({ label, value }, k) in data"
          :key="k"
          class="item"
          :class="{ active: value === selected.value }"
          @click="select({ label, value })"
        >
          <slot :name="value" />
          <span>{{ label }}</span>
          <span v-if="value === selected.value" class="ml-auto" style="position:absolute;right:10px;">
            <tickbare-svg />
          </span>
        </li>
      </ul>
    </div>
    <!-- </transition-group> -->
  </div>
</template>

<script>
export default {
  name: 'DropDown',
  props: {
    title: String,
    data: Array,
    dropUp: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: [Object, Array],
    primary: {
      type: Boolean,
      default: false,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      selected: this.value,
      offsetRight: 0,
    }
  },
  computed: {
    selectedLabel() {
      if (!this.selected) {
        return ''
      }
      return this.selected.label
    },
    tooLong() {
      return this.data.length > 8
    },
  },
  watch: {
    value(val) {
      this.selected = val
    },
    show() {
      const titleLeft = this.$refs.dropdown.getBoundingClientRect().left
      // const titleWidth = this.$refs.dropdown.offsetWidth
      const ww = window.innerWidth
      if (ww - titleLeft < 260) {
        this.offsetRight = 280 - (ww - titleLeft)
      }
    },
  },
  mounted() {},
  methods: {
    toggle() {
      if (this.disabled) {
        this.show = false
        return
      }
      this.show = !this.show
    },
    close() {
      this.show = false
    },
    select(val) {
      this.selected = val
      this.$emit('input', val)
      this.close()
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../styles/style.scss';
@import '../../styles/animation.scss';

$borderColor: #253043;
$borderColorAlpha: rgba(
  $color: #141a24,
  $alpha: 0.96,
);

.dropdown {
  min-width: 85px;
  height: 56px;
  font-size: 15px;
  background-color: transparent;
  outline: none;
  position: relative;
  z-index: 100;

  &:not(.dropdown--borderless) {
    border: 1px solid $borderColor;
    border-radius: 28px;

    &:focus {
      background-color: $borderColorAlpha;
    }
    &:hover {
      background-color: $borderColorAlpha;
    }
  }

  &.dropdown--primary {
    .dropdown__title {
      color: $primary;
      font-size: 26px;
      svg {
        font-size: 16px;
      }
    }
  }
  &__title {
    width: 100%;
    height: 100%;
    padding: 0 18px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    user-select: none;
  }

  .dropdown-arrow {
    transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
    &.flip {
      transform: rotate(180deg);
    }
  }
}

.dropdown__menu {
  width: 100%;
  min-width: 260px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 20px;
  margin: 0;
  margin-top: 4px;
  color: rgba(255, 255, 255, 0.7);
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 0;
  background-color: $borderColorAlpha;
  border: 1px solid #253043;
  border-radius: 20px;
  list-style-type: none;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  transform-origin: center top;

  &.tooLong {
    min-width: 320px;
    .item {
      width: 50%;
    }
  }

  .item {
    text-align: left;
    line-height: 40px;
    padding: 2px 18px 2px 12px;
    user-select: none;
    display: flex;
    align-items: center;
    position: relative;

    &:hover {
      background-color: #1f2937;
    }
    &.active {
      color: #fff;
    }
  }
}

.sort-icon {
  color: $primary;
}

.drop-up {
  width: 100%;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
}

.list-enter-active,
.list-leave-active,
.list-move {
  transition: all 0.05s ease-out;
  transform-origin: center top;
}
.drop-up {
  .list-enter-active,
  .list-leave-active,
  .list-move {
    transform-origin: center bottom;
  }
}
.list-enter {
  opacity: 0;
  /* transform: scaleY(0.5); */
  /* height: 0px; */
}
.list-enter-to {
  opacity: 1;
  /* transform: scaleY(1); */
  /* height: 100%; */
  animation: rotateMenu 0.5s;
}
.list-leave-active {
}
.list-leave-to {
  opacity: 0;
  /* height: 0px; */
  transform: scaleY(0);
}
</style>
