<template>
  <div
    class="dropdown"
    :class="{
      'dropdown--disabled': disabled,
    }"
    tabindex="0"
    @blur="close"
  >
    <div class="dropdown__title" @click="toggle">
      <!-- <slot name="title" />
      <span v-if="title">{{ title }}</span>
      <span v-else>{{ selectedLabel }}</span> -->
      <template v-if="!$scopedSlots['title']">
        <span v-if="title">{{ title }}</span>
        <span v-else>{{ selectedLabel }}</span>
      </template>
      <slot v-else name="title" :selected="selected" />
      <!-- <fa v-if="down" style="position:absolute;right:20px;font-size:10px" :icon="['fas', 'chevron-up']" />
      <fa v-else style="position:absolute;right:20px;font-size:10px" :icon="['fas', 'chevron-down']" /> -->
      <fa class="dropdown-arrow" :class="{ flip: down }" :icon="['fas', 'chevron-down']" />
      <!-- <fa v-else :icon="['fas', 'chevron-down']" /> -->
    </div>

    <transition-group name="list" tag="div" class="dropdown-transition" :class="{ 'drop-up': dropUp }">
      <ul v-if="down" role="menu" key="menu" class="dropdown__menu">
        <li
          role="option"
          class="item"
          v-for="({ label, value }, k) in data"
          :key="k"
          :class="{ active: selectIndex({ label, value }) > -1 }"
          @click="multiPick({ label, value })"
        >
          <slot :name="value" />
          <span>{{ label }}</span>
          <span v-if="selectIndex({ label, value }) > -1" class="ml-auto" style="position:absolute;right:20px;">
            <tickbare-svg />
          </span>
        </li>
        <divider key="divider" />
        <div key="actions" class="d-flex">
          <btn class="btn-secondary" style="flex: 1 1 50%" size="sm" @click="clear" tabindex="500">Clear</btn>
          <btn-color class="btn-primary ml-3" style="flex: 1 1 50%" size="sm" @click="apply">Apply</btn-color>
        </div>
      </ul>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'DropDown',
  props: {
    title: String,
    data: Array,
    dropUp: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: [Object, Array],
  },
  data() {
    return {
      down: false,
      selected: this.value,
    }
  },
  computed: {
    selectedLabel() {
      if (!this.selected || this.selected.length === 0) {
        return '-'
      }
      if (this.selected.length === this.data.length) {
        return '全部'
      }
      let str = ''
      for (let i = 0; i < this.selected.length; i++) {
        str += this.selected[i].label
        if (i !== this.selected.length - 1) {
          str += '，'
        }
      }
      return str
    },
  },
  watch: {
    value(val) {
      this.selected = val
    },
  },
  mounted() {},
  methods: {
    toggle() {
      if (this.disabled) {
        this.down = false
        return
      }
      this.down = !this.down
    },
    close() {
      this.down = false
    },
    multiPick(item) {
      const v = this.selected
      const index = this.selectIndex(item)
      if (index === -1) {
        v.push(item)
      } else {
        v.splice(index, 1)
      }
      this.$emit('input', v)
    },
    selectIndex(item) {
      for (let i = 0; i < this.selected.length; i++) {
        if (this.selected[i].value === item.value) return i
      }
      return -1
    },
    clear() {
      this.$emit('input', [])
    },
    apply() {},
  },
}
</script>

<style scoped lang="scss">
@import '../../styles/style.scss';
@import '../../styles/animation.scss';

$borderColor: #253043;
$borderColorAlpha: rgba(
  $color: #141a24,
  $alpha: 0.96,
);

.dropdown {
  min-width: 125px;
  height: 56px;
  font-size: 15px;
  background-color: transparent;
  border: 1px solid $borderColor;
  border-radius: 28px;
  outline: none;
  position: relative;
  z-index: 100;

  &:focus {
    background-color: $borderColorAlpha;
  }
  &:hover {
    background-color: $borderColorAlpha;
  }
  &__title {
    width: 100%;
    height: 100%;
    padding: 0 18px;
    color: #fff;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .dropdown-arrow {
    transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
    &.flip {
      transform: rotate(180deg);
    }
  }

  &__menu {
    /* width: 100%; */
    min-width: 260px;
    padding: 20px;
    margin: 0;
    margin-top: 4px;
    color: rgba(255, 255, 255, 0.7);
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 0;
    background-color: $borderColorAlpha;
    border: 1px solid #253043;
    border-radius: 20px;
    list-style-type: none;
    cursor: pointer;
    overflow: hidden;
    position: absolute;

    .item {
      text-align: left;
      line-height: 40px;
      padding: 2px 18px 2px 12px;
      user-select: none;
      display: flex;
      align-items: center;

      &:hover {
        background-color: #1f2937;
      }
      &.active {
        color: #fff;
        .square {
          background-color: $primary;
        }
      }

      .square {
        width: 10px;
        height: 10px;
        margin-right: 10px;
        background-color: #0b0e18;
        border-radius: 2px;
      }
    }
    .close-btn {
      height: 50px;
      font-size: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.sort-icon {
  color: $primary;
}

.drop-up {
  width: 100%;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
}

.list-enter-active,
.list-leave-active,
.list-move {
  transition: all 0.05s ease-out;
  transform-origin: center top;
}
.drop-up {
  .list-enter-active,
  .list-leave-active,
  .list-move {
    transform-origin: center bottom;
  }
}
.list-enter {
  opacity: 0;
  transform: scaleY(0.5);
  height: 0px;
}
.list-enter-to {
  opacity: 1;
  /* transform: scaleY(1);
  height: 100%; */
  animation: rotateMenu 0.5s;
}
.list-leave-active {
}
.list-leave-to {
  opacity: 0;
  height: 0px;
  transform: scaleY(0);
}
</style>
