import { LOAD_USER, SET_USER, SET_NOT_LOGIN, SET_LOGIN, SET_ERROR, LOAD_NUM_OFFER, SET_NUM_OFFER } from './types'
import { UserService, AppService } from '../services/api'
import { saveToken } from '../services/jwt'

export default {
  async [LOAD_USER](context) {
    context.commit('SET_USER_LOADING', true)
    try {
      const { data } = await UserService.getUserInfo()
      context.commit(SET_USER, data)
    } catch (e) {
      context.commit(SET_NOT_LOGIN)
      UserService.refreshToken().catch(e => {
        context.commit(SET_NOT_LOGIN)
      })
      // throw 'fail'
    } finally {
      context.commit('SET_USER_LOADING', false)
    }
  },

  async [SET_LOGIN](context, payload) {
    saveToken(payload)
    try {
      await context.dispatch(LOAD_USER)
      context.commit(SET_LOGIN, payload)
    } catch (e) {}
  },

  async [LOAD_NUM_OFFER](context) {
    try {
      if (!context.state.user.isLogin) {
        context.commit(SET_NUM_OFFER, 0)
        return
      }
      const { data } = await AppService.getItems()
      context.commit(SET_NUM_OFFER, data.length)
    } catch (e) {
      context.commit(SET_ERROR, e)
    }
  },

  ['SET_ADDRESS'](context, payload) {
    context.commit('SET_ADDRESS', payload)
  },

  ['SET_BALANCE'](context, payload) {
    context.commit('SET_BALANCE', payload)
  },

  ['SET_LANG'](context, payload) {
    context.commit('SET_LANG', payload)
    localStorage.setItem('lang', payload)
  },
}
