<template>
  <div
    class="dropdown"
    :class="{
      'dropdown--disabled': disabled,
      // 'dropdown--primary': primary,
      // 'dropdown--borderless': borderless,
    }"
    ref="dropdown"
    tabindex="-1"
    @blur="close"
  >
    <div class="dropdown__title" @click="toggle">
      <dollar-svg />
      <span>{{ $t('components.dropdown-pricerange.pri') }}</span>
      <fa class="dropdown-arrow" :class="{ flip: show }" :icon="['fas', 'chevron-down']" />
    </div>

    <transition-group name="list" tag="div" class="dropdown-transition" :class="{ 'drop-up': dropUp }">
      <div v-if="show" key="menu" class="dropdown__menu">
        <i-select :options="options" v-model="type" tabindex="-1" @blur="close"> </i-select>

        <div class="d-flex mt-4">
          <i-input v-model="min" clean class="mr-3" placeholder="From" @blur="close"></i-input>
          <i-input v-model="max" clean placeholder="To" @blur="close"></i-input>
        </div>

        <divider key="divider" />

        <div key="actions" class="d-flex">
          <btn
            class="btn-secondary"
            style="flex: 1 1 50%"
            size="sm"
            @click="clear"
            ref="clearBtn"
            tabindex="-1"
            @blur="close"
          >
            {{ $t('components.dropdown-pricerange.cle') }}
          </btn>
          <btn-color class="btn-primary ml-3" style="flex: 1 1 50%" size="sm" @click="apply" @blur="close">
            {{ $t('components.dropdown-pricerange.app') }}
          </btn-color>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
const OPTIONS = [
  { label: 'USD', value: 'usd' },
  { label: 'ETH', value: 'eth' },
]

export default {
  name: 'DropDown',
  props: {
    dropUp: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: [Object],
    // primary: {
    //   type: Boolean,
    //   default: false,
    // },
    // borderless: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      show: false,
      options: OPTIONS,
      type: '',
      min: '',
      max: '',
    }
  },
  computed: {},
  watch: {
    value(val) {
      this.type = val.type
      this.min = val.min
      this.max = val.max
    },
  },
  created() {
    this.resetValue()
  },
  mounted() {},
  methods: {
    toggle() {
      if (this.disabled) {
        this.show = false
        return
      }
      this.show = !this.show
    },
    close(e) {
      if (this.$refs.dropdown.contains(e.relatedTarget)) return
      this.show = false
      this.resetValue()
    },
    clear() {
      this.$emit('input', { type: 'usd', min: '', max: '' })
      // this.show = false
    },
    apply() {
      this.$emit('input', { type: this.type, min: this.min, max: this.max })
      this.show = false
    },
    resetValue() {
      if (this.value) {
        this.type = this.value.type
        this.min = this.value.min
        this.max = this.value.max
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../styles/style.scss';
@import '../../styles/animation.scss';

$borderColor: #253043;
$borderColorAlpha: rgba(
  $color: #141a24,
  $alpha: 0.96,
);

.dropdown {
  min-width: 85px;
  height: 56px;
  font-size: 15px;
  background-color: transparent;
  outline: none;
  position: relative;
  z-index: 100;

  &:not(.dropdown--borderless) {
    border: 1px solid $borderColor;
    border-radius: 28px;

    &:focus {
      background-color: $borderColorAlpha;
    }
    &:hover {
      background-color: $borderColorAlpha;
    }
  }

  &.dropdown--primary {
    .dropdown__title {
      color: $primary;
      font-size: 26px;
      svg {
        font-size: 16px;
      }
    }
  }
  &__title {
    width: 100%;
    height: 100%;
    padding: 0 18px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    user-select: none;
  }

  .dropdown-arrow {
    transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
    &.flip {
      transform: rotate(180deg);
    }
  }

  &__menu {
    width: 100%;
    min-width: 260px;
    padding: 20px;
    margin: 0;
    margin-top: 4px;
    color: rgba(255, 255, 255, 0.7);
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 0;
    background-color: $borderColorAlpha;
    border: 1px solid #253043;
    border-radius: 20px;
    list-style-type: none;
    cursor: pointer;
    overflow: hidden;
    position: absolute;
  }
}

.sort-icon {
  color: $primary;
}

.drop-up {
  width: 100%;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
}

.list-enter-active,
.list-leave-active,
.list-move {
  transition: all 0.05s ease-out;
  transform-origin: center top;
}
.drop-up {
  .list-enter-active,
  .list-leave-active,
  .list-move {
    transform-origin: center bottom;
  }
}
.list-enter {
  opacity: 0;
  /* transform: scaleY(0.5); */
  /* height: 0px; */
}
.list-enter-to {
  opacity: 1;
  /* transform: scaleY(1); */
  /* height: 100%; */
  animation: rotateMenu 0.5s;
}
.list-leave-active {
}
.list-leave-to {
  opacity: 0;
  /* height: 0px; */
  transform: scaleY(0);
}
</style>
