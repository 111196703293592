<template>
  <div
    class="i-input"
    :class="{ 'not-empty': iValue, 'is-error': isError, headShake: isError, clean }"
    :style="{ width }"
  >
    <input v-bind="$attrs" v-model="iValue" @blur="onBlur" />
    <legend v-if="!clean" class="i-input__title">
      <slot />
    </legend>
    <label v-if="isError" class="error-message" for="">{{ errorMsgC }}</label>
    <div class="i-input__icon" v-if="!clean">
      <span class="text-10 text-secondary">{{$currencySign}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Input',
  props: {
    value: [String, Number],
    width: { type: String, default: '100%' },
    min: { type: Number, default: 0 },
    errorMsg: { type: String, default: '错误' },
    clean: { type: Boolean, default: false },
  },
  data() {
    return {
      iValue: '',
      isError: false,
    }
  },
  computed: {
    errorMsgC() {
      if (this.iValue === '') return '请填写金额'
      if (this.iValue < this.min) return `金额必须大于 ${this.min}`
      return '错误'
    },
  },
  created() {
    if (this.value) {
      this.iValue = this.value
    }
  },
  watch: {
    value(val) {
      if (val >= this.min) {
        this.isError = false
      }
      this.iValue = val
    },
    iValue(val) {
      if (val >= this.min) {
        this.isError = false
      }
      this.$emit('input', val)
    },
  },
  methods: {
    onBlur(e) {
      this.$emit('blur', e)
      if (this.iValue < this.min) {
        this.isError = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.i-input {
  position: relative;
  width: 180px;
  height: 42px;
}

.i-input.clean {
  input {
    padding-left: 12px;
  }
}

.i-input.is-error {
  input {
    border-color: red;
  }
  .i-input__title {
    color: red;
  }
}

.error-message {
  margin: 0;
  position: absolute;
  left: 0;
  bottom: -20px;
  color: red;
  font-family: sans-serif;
}

.i-input.not-empty {
  .i-input__title {
    top: 0;
    z-index: 2;
  }
  .i-input__icon {
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
  }
}

input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #253043;
  box-sizing: border-box;
  color: #94a4be;
  font-size: 1rem;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05));
  border-radius: 4px;
  background-color: transparent;
  padding-left: 30px;
  z-index: 1;

  &:hover,
  &:focus {
    border: 1px solid #5c6c87;
    /* background-color: rgba(92, 108, 135, 0.2); */
    color: #94a4be;
    box-shadow: none;
    outline: none;
  }

  &:focus + .i-input__title {
    top: 0;
    z-index: 2;
  }

  &:focus ~ .i-input__icon {
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.i-input__title {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  transition: all 200ms ease;

  font-size: 12px;
  font-weight: 700;
  margin: 0;
  line-height: 1;
  height: 1em;
  padding: 0;
  display: inline-block;
  width: auto;
  box-shadow: 0 0 0 7px #141a24;
  background: #141a24;
  color: #5c6c87;
  letter-spacing: 1px;
  z-index: 0;
}

.i-input__icon {
  position: absolute;
  left: 15px;
  top: 100%;
  opacity: 0;
  transition: all 200ms ease;
}
</style>
