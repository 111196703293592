const ID_TOKEN_KEY = 'jwt-token'

export const getToken = () => window.localStorage.getItem(ID_TOKEN_KEY)

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token)
}

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY)
  window.location.href = '/'
}

export default { getToken, saveToken, destroyToken }
