<template>
  <div class="mr-auto" style="flex:1 1 0%" ref="dropdown">
    <search-input-new
      v-model="keyword"
      :placeholder="$t('navbar.search.h')"
      width="100%"
      autoUpdate
      @blur="close"
      @focus="onFocus"
      @enter="goSearch"
      @complete="completeSearch"
    ></search-input-new>

    <transition-group name="list" tag="div" class="dropdown-transition">
      <div v-if="show" key="menu" class="dropdown__menu max-w-full" :style="`left:-${offsetRight}px;`">
        <b-spinner small v-if="loading"></b-spinner>
        <!-- <div v-if="hasResult">
          <h5 class="text-white">游戏</h5>
          <div class="py-2">
            <div class="py-2 d-flex">
              <avatar
                size="40px"
                imgUrl="https://lh3.googleusercontent.com/UAJwyDV1j9py1bUGSuy5AVFvdupYZfOf1qHuHA6AubBQSCgSRyGi9i5qiSxHqwz_D6C-GVxKeexJBH_bF0llpX-xV96dJmC0a1VLoA=s168"
              />
              <div class="pl-3">
                <h6 class="m-0">Tiger NFT Genesis Collection</h6>
                <small>1000个物品</small>
              </div>
            </div> 
          </div>
        </div> -->
        <!-- <divider v-if="hasResult" /> -->
        <div v-if="hasResult">
          <h5 class="text-white">装备</h5>
          <div class="py-2">
            <router-link
              class="not-link"
              v-for="(item, k) in result"
              :key="k"
              :to="'/store/' + item.item.market_hash_name"
            >
              <div class="py-2 pl-2 d-flex result-item">
                <avatar size="40px" :imgUrl="$assetUrl + item.item.image_url" />
                <div class="pl-3">
                  <h6 class="m-0 text-white">{{ item.item.name }}</h6>
                  <small class="text-secondary">{{ item.item.app_id === 730 ? 'CSGO' : 'DOTA2' }}</small>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <btn
          v-if="loading || hasResult"
          class="btn-outline-secondary w-full mt-4"
          @click="$router.push(`/search?keyword=${keyword}`)"
        >
          {{ $t('navbar.search.all') }}
        </btn>
        <div v-if="!loading && !hasResult">没找到结果</div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { AppService } from '@/services/api'

export default {
  name: 'SearchGlobal',
  props: {},
  data() {
    return {
      keyword: '',
      show: false,
      result: null,
      loading: false,
      offsetRight: 0,
    }
  },
  computed: {
    hasResult() {
      return this.result && this.result.length > 0
    },
  },
  watch: {
    keyword(val) {
      // this.result = null
      // if (val && val.length > 1) {
      //   this.show = true
      //   this.search()
      // } else {
      //   this.show = false
      // }
    },
    $route() {
      this.show = false
    },
    show() {
      const titleLeft = this.$refs.dropdown.getBoundingClientRect().left
      // const titleWidth = this.$refs.dropdown.offsetWidth
      const ww = window.innerWidth
      if (ww > 900) {
        this.offsetRight = 0 - titleLeft
      }
      if (ww - titleLeft < 260) {
        this.offsetRight = 260 - (ww - titleLeft)
      }
    },
  },
  mounted() {},
  methods: {
    completeSearch() {
      this.result = null
      if (this.keyword && this.keyword.length > 1) {
        this.show = true
        this.search()
      } else {
        this.show = false
      }
    },
    close(e) {
      if (this.$refs.dropdown.contains(e.relatedTarget)) return
      this.show = false
    },
    onFocus() {
      if (this.keyword && this.keyword.length > 1) this.show = true
    },
    search() {
      this.loading = true
      AppService.searchGlobal(this.keyword)
        .then(({ data }) => {
          this.result = data.data.slice(0, 4)
        })
        .catch()
        .finally(() => {
          this.loading = false
        })
    },
    goSearch() {
      this.show = false
      this.$router.push(`/search?keyword=${this.keyword}`)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/style.scss';
@import '../../styles/animation.scss';

.result-item {
  &:hover {
    background-color: #253043;
  }
}

.dropdown__menu {
  width: 100%;
  min-width: 260px;
  max-width: 800px;
  padding: 24px;
  margin: 0;
  margin-top: 4px;
  color: rgba(255, 255, 255, 0.7);
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 0;
  background-color: #141a24;
  border: 1px solid #253043;
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  z-index: 201;
}
.list-enter {
  opacity: 0;
  /* transform: scaleY(0.5); */
  /* height: 0px; */
}
.list-enter-to {
  opacity: 1;
  /* transform: scaleY(1); */
  /* height: 100%; */
  animation: rotateMenu 0.5s;
}
.list-leave-active {
  opacity: 1;
}
.list-leave-to {
  opacity: 0;
  /* height: 0px; */
  transform: scaleY(0);
}
</style>
