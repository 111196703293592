<template>
  <div class="d-flex flex-wrap" style="gap:20px">
    <chip @click="changeValue(i)" v-for="i in options" :key="i.value" :active="dValue === i.value">
      <slot :name="i.value" :item="i" :label="i.label" />
      <span v-if="!$scopedSlots[i.value]">{{ i.value }}</span>
    </chip>
  </div>
</template>

<script>
import Chip from './Chip.vue'

export default {
  name: 'ChipSelect',
  components: {
    Chip,
  },
  props: {
    value: String,
    options: Array,
  },
  data() {
    return {
      dValue: this.value,
    }
  },
  watch: {
    value(val) {
      this.dValue = val
    },
  },
  methods: {
    changeValue(i) {
      if (this.dValue === i.value) {
        this.dValue = ''
      } else {
        this.dValue = i.value
      }
      this.$emit('input', this.dValue)
    },
  },
}
</script>

<style lang="scss" scoped>
.chip {
  opacity: 0.7;
}
.chip.active {
  opacity: 1;
}
</style>
