import AppModal from '@/components/Modal'

const Modal = {
  // every plugin for Vue.js needs install method
  // this method will run after Vue.use(<your-plugin-here>) is executed
  install(Vue) {
    this.EventBus = new Vue()

    Vue.component('Modal', AppModal)

    Vue.prototype.$modal = {
      show(id) {
        Modal.EventBus.$emit('show', id)
      },
      hide(id) {
        Modal.EventBus.$emit('hide', id)
      },
    }
  },
}

export default Modal
