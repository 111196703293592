<template>
  <div class="weapon-card" ref="card">
    <img v-bind="$attrs" alt="" class="weapon-img" :class="`weapon-img--${shadowSize}`" ref="weaponImage" />
  </div>
</template>

<script>
export default {
  name: 'WeaponImg',
  props: {
    shadowSize: { type: String, default: 'md' },
  },
  mounted() {
    const card = this.$refs.card

    card.addEventListener('mousemove', ev => {
      this.Card3D(card, ev)
    })

    card.addEventListener('mouseleave', ev => {
      const img = this.$refs.weaponImage

      img.style.transform = 'rotateX(0deg) rotateY(0deg)'
      img.style.filter = 'brightness(1)'
    })
  },
  methods: {
    map(val, minA, maxA, minB, maxB) {
      return minB + ((val - minA) * (maxB - minB)) / (maxA - minA)
    },
    Card3D(card, ev) {
      const img = this.$refs.weaponImage
      const imgRect = card.getBoundingClientRect()
      const width = imgRect.width
      const height = imgRect.height
      const mouseX = ev.offsetX
      const mouseY = ev.offsetY
      const rotateY = this.map(mouseX, 0, width, -25, 25)
      const rotateX = this.map(mouseY, 0, height, 25, -25)
      const brightness = this.map(mouseY, 0, height, 1.5, 0.5)

      img.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`
      img.style.filter = `brightness(${brightness})`
    },
  },
}
</script>

<style lang="scss" scoped>
.weapon-img {
  -webkit-user-drag: none;
  filter: drop-shadow(0 10px 3px rgba(0, 0, 0, 0.25));
  /* background-color: #555; */

  &--sm {
    filter: drop-shadow(0 10px 3px rgba(0, 0, 0, 0.25));
  }
  &--md {
    filter: drop-shadow(0 10px 3px rgba(0, 0, 0, 0.25));
  }
  &--lg {
    filter: drop-shadow(0px 20px 6px rgba(0, 0, 0, 0.25));
  }
}

.weapon-card {
  width: 100%;
  margin: 4px;
  transform: scale(1);
  perspective: 600px;
  transition: all 250ms ease-out;

  &:hover {
    z-index: 10;
    transform: scale(1.3);
  }
}

.weapon-img {
  width: 100%;
  user-select: none;
  transition: all 250ms ease-out;
}
</style>
