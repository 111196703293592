<template>
  <!-- <div class="lang-select" tabindex="0" @blur="open = false">
    <div class="lang-select__selected" @click="open = !open">
      <img :src="require(`../assets/${selected}.svg`)" width="20px" />
    </div>
    <div class="lang-select__items" :class="{ selectHide: !open }">
      <div v-for="(option, i) of options" :key="i" @click="setLocale(option)">
        <img :src="require(`../assets/${option.value}.svg`)" width="20px" />
        <cn-svg />
        {{ option.text }}
      </div>
    </div>
  </div> -->
  <drop-down v-model="selected" @input="selectLang" :value="{ selected }" :data="options" borderless>
    <template v-slot:title="{ selected }">
      <span v-if="selected.value === 'cn'"> <cn-svg /></span>
      <span v-if="selected.value === 'en'"> <en-svg /></span>
    </template>
    <template v-slot:cn="{ item }">
      <cn-svg />
      <span class="ml-2">{{ item }}</span>
    </template>
    <template v-slot:en="{ item }">
      <en-svg />
      <span class="ml-2">{{ item }}</span>
    </template>
  </drop-down>
</template>

<script>
export default {
  name: 'LangSelect',
  data() {
    return {
      options: [
        {
          value: 'en',
          label: 'English',
        },
        {
          value: 'cn',
          label: '中文',
        },
      ],
      open: false,
      selected: {},
    }
  },
  mounted() {
    this.getLang()
  },
  watch: {},
  methods: {
    getLang() {
      let rv
      const l = this.$store.state.lang
      if (l) {
        rv = this.options.find(i => i.value === l)
      } else {
        rv = this.options[1]
      }
      this.selected = rv
    },
    selectLang(v) {
      this.setLocale(v)
    },
    setLocale(option) {
      this.$store.dispatch('SET_LANG', option.value)
      this.selected = option
      this.open = false
      // window.location.assign(window.location.origin)
      window.location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../styles/style';

.lang-select {
  width: 80px;
  height: 40px;
  position: relative;
  text-align: left;
  outline: none;
  line-height: 50px;
  margin: 0 10px;

  &__selected {
    height: 40px;
    background-color: transparent;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
    border-radius: 5px;
    display: flex;

    &:after {
      position: absolute;
      content: '';
      top: 18px;
      right: 28px;
      border: 5px solid transparent;
    }
  }

  &__items {
    width: 120px;
    border-radius: 3px;
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;

    div {
      padding-left: 1em;
      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: #fff;
      }
    }
  }

  .selectHide {
    display: none;
  }
}
</style>
