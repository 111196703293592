var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',{attrs:{"name":"overlay"}},[(_vm.visible)?_c('div',{key:"overlay",staticClass:"window-overlay",on:{"click":_vm.hide}}):_vm._e()]),(_vm.visible)?_c('div',{key:"window",staticClass:"window",attrs:{"tabindex":"-1"}},[_c('div',{staticClass:"window__inner"},[_c('transition',{attrs:{"name":"modal"}},[_c('div',{staticClass:"window__block",class:[{}]},[_c('div',{staticClass:"window__modal-inner",class:[
              ("rounded-" + _vm.rounded),
              {
                'window__modal-no-dialog': _vm.noDialog,
                'bg-white': _vm.variant === 'light',
                'text-black': _vm.variant === 'light',
                'text-white': _vm.variant !== 'light',
              } ],style:([
              {
                width: _vm.width,
                background: _vm.bg,
                padding: _vm.padding,
              } ])},[_vm._t("default"),(!_vm.hideClose)?_c('button',{staticClass:"window__close",on:{"click":function($event){return _vm.$modal.hide(_vm.id)}}},[_vm._v("×")]):_vm._e(),(!_vm.hideHeader && !_vm.noDialog)?_c('div',{staticClass:"window__header",class:[
                {
                  'bg-white': _vm.variant === 'light',
                  'text-[#4B5563]': _vm.variant === 'light',
                } ]},[(!_vm.$scopedSlots.header)?_c('div',{staticClass:"window__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_vm._t("header")],2):_vm._e(),(!_vm.noDialog)?_c('div',{staticClass:"window__content",style:([
                {
                  height: _vm.height,
                  maxHeight: _vm.maxHeight,
                } ])},[_vm._t("body")],2):_vm._e(),(!_vm.hideFooter && !_vm.noDialog)?_c('div',{staticClass:"window__footer"},[_vm._t("footer")],2):_vm._e()],2)])])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }