<template>
  <b-modal
    content-class="modal-size"
    centered
    title="充值"
    size="lg"
    @hide="onHide()"
    @show="onShow()"
    footer-class="d-block"
    no-close-on-backdrop
    no-close-on-esc
    id="topup"
    :visible="modalVisible"
  >
    <div class="topup" v-if="!qrcodeUrl && false">
      <!-- cdk -->
      <template v-if="type === 'cdk'">
        <strong class="select-type" @click="onShow">
          <span class="arrow"> <FIcon :icon="['fa', 'chevron-left']" /> </span>
          选择充值方式
        </strong>
        <b-form-group label-cols-sm="3" label="充值码:" label-align-sm="left" label-for="cdk">
          <b-input-group>
            <b-form-input
              :disabled="generating"
              type="text"
              placeholder="输入16位CDK"
              id="cdk"
              maxlength="16"
              v-model="cdk"
            />
            <b-input-group-append>
              <b-button @click="topupCdk" variant="primary" class="ml-2" :disabled="generating">兑换 </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-form-invalid-feedback :state="cdk && cdk.length === 16" id="cdkValidationResult">
            CDK 格式不合法
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="text-left m-2">
          以您需要的方式购买 <span>SkinsGift</span> 钱包卡（CDKey）。 通过微信支付，支付宝，银联，QQ钱包等方式。
        </div>
        <div class="d-flex flex-wrap">
          <div class="cdk-item" v-for="cdk in fastCdkLink" :key="cdk.amount">
            <a target="_blank" class="btn" :href="cdk.link"> ${{ cdk.amount }} </a>
          </div>
        </div>
        <span class="text-danger" v-if="cdkAmount > 0"
          >成功充值 ${{ cdkAmount }} 奖励${{ cdkAmount * user.info.cdk_discount }}
        </span>
      </template>
      <!-- cxka alipay -->
      <template v-else-if="type === 'alipay'">
        <strong class="select-type" @click="onShow">
          <span class="arrow"> <FIcon :icon="['fa', 'chevron-left']" /> </span>
          选择充值方式</strong
        >
        <b-form-group label-cols-sm="3" label="充值额度: $" label-align-sm="left" label-for="otp">
          <b-input-group>
            <b-form-input
              :disabled="generating"
              type="number"
              placeholder="充值额度"
              id="otp"
              maxlength="6"
              :min="3"
              :max="200"
              v-model="amount"
            />
            <b-input-group-append>
              <b-button @click="topup" variant="primary" class="ml-2" :disabled="generating">充值 </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-form-invalid-feedback :state="amount >= 3 && amount <= 200" id="tokenValidationResult">
            充值金额必须在 $3 - $200之间
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="row">
          <div class="col-3"></div>
          <div class="col-md-9">
            <span v-if="generating">正在生成二维码，请稍后...</span>
            <span v-if="!generating">请输入充值金额，生成二维码</span>
          </div>
        </div>
      </template>
      <!-- alipay -->
      <template v-else-if="type === 'alipay-ext'">
        <strong class="select-type" @click="onShow">
          <span class="arrow"> <FIcon :icon="['fa', 'chevron-left']" /> </span>
          选择充值方式</strong
        >
        <b-form-group label-cols-sm="3" label="充值额度: $" label-align-sm="left" label-for="otp">
          <b-input-group>
            <b-form-input
              :disabled="generating"
              type="number"
              placeholder="充值额度"
              id="otp"
              maxlength="6"
              :min="3"
              :max="200"
              v-model="amount"
            />
            <b-input-group-append>
              <b-button @click="topup" variant="primary" class="ml-2" :disabled="generating">充值 </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-form-invalid-feedback :state="amount >= 3 && amount <= 200" id="tokenValidationResult">
            充值金额必须在 $3 - $200之间
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="row">
          <div class="col-3"></div>
          <div class="col-md-9">
            <span v-if="generating">正在生成二维码，请稍后...</span>
            <span v-if="!generating">请输入充值金额，生成二维码</span>
          </div>
        </div>
      </template>
      <!-- alipay face -->
      <template v-else-if="type === 'alipay-face'">
        <strong class="select-type" @click="onShow">
          <span class="arrow"> <FIcon :icon="['fa', 'chevron-left']" /> </span>
          选择充值方式</strong
        >
        <b-form-group label-cols-sm="3" label="充值额度: $" label-align-sm="left" label-for="otp">
          <b-input-group>
            <b-form-input
              :disabled="generating"
              type="number"
              placeholder="充值额度"
              id="otp"
              maxlength="6"
              :min="3"
              :max="200"
              v-model="amount"
            />
            <b-input-group-append>
              <b-button @click="topup" variant="primary" class="ml-2" :disabled="generating">充值 </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-form-invalid-feedback :state="amount >= 3 && amount <= 200" id="tokenValidationResult">
            充值金额必须在 $3 - $200之间
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label-cols-sm="3" label="手机号" label-align-sm="left" label-for="mobile">
          <b-input-group>
            <b-form-input
              :disabled="generating"
              type="number"
              placeholder="手机号"
              id="mobile"
              maxlength="11"
              v-model="mobile"
            />
          </b-input-group>
          <b-form-invalid-feedback :state="parseInt(mobile).toString().length === 11" id="tokenValidationResult">
            手机号不合法
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label-cols-sm="3" label="支付宝账号" label-align-sm="left" label-for="alipayAccount">
          <b-input-group>
            <b-form-input
              :disabled="generating"
              placeholder="请填写支付宝账号"
              id="alipayAccount"
              v-model="alipayAccount"
            />
          </b-input-group>
          <b-form-invalid-feedback :state="alipayAccount.length > 5" id="tokenValidationResult">
            支付宝账号不合法
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="row">
          <div class="col-3"></div>
          <div class="col-md-9">
            <span v-if="generating">正在生成二维码，请稍后...</span>
            <span v-if="!generating">请输入充值金额，生成二维码</span>
          </div>
        </div>
      </template>
      <!-- 4-2 alipay app -->
      <template v-else-if="type === 'alipay-app'">
        <strong class="select-type" @click="onShow">&lt; 选择充值方式</strong>
        <b-form-group label-cols-sm="3" label="充值额度: $" label-align-sm="right" label-for="otp">
          <b-input-group>
            <b-form-input
              :disabled="generating"
              type="number"
              placeholder="充值额度"
              id="otp"
              maxlength="6"
              :min="5"
              :max="200"
              v-model="amount"
            />
            <b-input-group-append>
              <b-button @click="topup" class="ml-2" variant="primary" :disabled="generating">充值 </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-form-invalid-feedback :state="amount >= 5 && amount <= 200" id="tokenValidationResult">
            充值金额必须在 $5 - $200之间
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="row">
          <div class="col-3"></div>
          <div class="col-md-9">
            <span v-if="generating">正在生成充值链接，请稍后...</span>
            <span v-if="!generating">请输入充值金额，生成充值链接</span>
          </div>
        </div>
      </template>
      <!-- wechat -->
      <template v-else-if="type === 'wechat'">
        <strong class="select-type" @click="onShow">
          <span class="arrow"> <FIcon :icon="['fa', 'chevron-left']" /> </span>
          选择充值方式</strong
        >
        <b-form-group label-cols-sm="3" label="充值额度: $" label-align-sm="left" label-for="otp">
          <b-input-group>
            <b-form-input
              :disabled="generating"
              type="number"
              placeholder="充值额度"
              id="otp"
              maxlength="6"
              :min="3"
              :max="200"
              v-model="amount"
            />
            <b-input-group-append>
              <b-button @click="topup" variant="primary" class="ml-2" :disabled="generating">充值 </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-form-invalid-feedback :state="amount >= 3 && amount <= 200" id="tokenValidationResult">
            充值金额必须在 $3 - $200之间
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="row">
          <div class="col-3"></div>
          <div class="col-md-9">
            <span v-if="generating">正在生成二维码，请稍后...</span>
            <span v-if="!generating">请输入充值金额，生成二维码</span>
          </div>
        </div>
      </template>
      <!-- 5-2 wechat app -->
      <template v-else-if="type === 'wechat-app'">
        <strong class="select-type" @click="onShow">&lt; 选择充值方式</strong>
        <b-form-group label-cols-sm="3" label="充值额度: $" label-align-sm="right" label-for="otp">
          <b-input-group>
            <b-form-input
              :disabled="generating"
              type="number"
              placeholder="充值额度"
              id="otp"
              maxlength="6"
              :min="5"
              :max="200"
              v-model="amount"
            />
            <b-input-group-append>
              <b-button @click="topup" class="ml-2" variant="primary" :disabled="generating">充值 </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-form-invalid-feedback :state="amount >= 5 && amount <= 200" id="tokenValidationResult">
            充值金额必须在 $5 - $200之间
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="row">
          <div class="col-3"></div>
          <div class="col-md-9">
            <span v-if="generating">正在生成充值链接，请稍后...</span>
            <span v-if="!generating">请输入充值金额，生成充值链接</span>
          </div>
        </div>
      </template>
      <!-- refer -->
      <template v-else-if="type === 'ref-setting'">
        <strong class="select-type" @click="onShow">
          <span class="arrow"> <FIcon :icon="['fa', 'chevron-left']" /> </span>
          选择充值方式</strong
        >
        <ref-setting :disable="false" :generating="generating"></ref-setting>
      </template>
    </div>

    <div class="topup" v-if="!qrcodeUrl">
      <template>
        <strong class="select-type-main">请选择充值金额及支付方式：</strong>
        <div class="topup-select">
          <div class="d-flex align-items-start w-full mt-4 mb-2">
            <span style="width:120px;line-height:40px" class="text-secondary">请输入充值金额：</span>
            <div class="d-flex flex-wrap gap-10 w-full">
              <i-input v-model="amount" type="number" :min="20" class="w-full">
                充值金额
              </i-input>
            </div>
          </div>

          <div class="d-flex align-items-start w-full mt-4 mb-2">
            <span style="width:120px;line-height:40px" class="text-secondary">请选择充值金额：</span>
            <div class="d-flex gap-10 w-full">
              <chip style="flex:1 1 25%" :class="{ active: amount === 20 }" @click="amount = 20">{{$currencySign}} 20</chip>
              <chip style="flex:1 1 25%" :class="{ active: amount === 50 }" @click="amount = 50">{{$currencySign}} 50</chip>
              <chip style="flex:1 1 25%" :class="{ active: amount === 100 }" @click="amount = 100">{{$currencySign}} 100</chip>
              <chip style="flex:1 1 25%" :class="{ active: amount === 300 }" @click="amount = 300">{{$currencySign}} 300</chip>
            </div>
          </div>

          <div class="d-flex align-items-start w-full mt-4">
            <span style="width:120px;line-height:40px" class="text-secondary">请选择充值方式：</span>
            <div class="d-flex flex-wrap gap-10 w-full">
              <!-- <chip :class="{ active: type === 'wechat' }" @click="type = 'wechat'">
                <fa :icon="['fab', 'weixin']" />
                <span class="ml-1">微信支付</span>
                <span class="text-9 ml-2">扫码支付</span>
              </chip> -->
              <chip :class="{ active: type === 'alipay' }" @click="type = 'alipay'">
                <fa :icon="['fab', 'alipay']" />
                <span class="ml-1">支付宝</span>
                <span class="text-9 ml-2">扫码支付</span>
              </chip>
            </div>
          </div>
          <div class="d-flex w-full mt-4" style="padding-left: 93px">
            <btn class="btn-primary" :disabled="!amount || !type || amount < 3" :loading="generating" @click="topup">
              充值
            </btn>
          </div>
        </div>
      </template>
    </div>

    <div class="topup" v-if="qrcodeUrl">
      <strong class="select-type" @click="onShow">
        <span class="arrow"> <FIcon :icon="['fa', 'chevron-left']" /> </span>
        返回上一步
      </strong>
      <template>
        <strong class="cnyAmount" v-if="cnyAmount"
          >充值金额为：<span>￥{{ cnyAmount }}</span></strong
        >
        <div v-if="type === 'alipay-ext'" class="d-flex justify-content-center m-1">
          <a class="btn" target="_blank" :href="qrcodeUrl">点击此处完成支付</a>
        </div>
        <div v-if="type === 'alipay-app' || type === 'wechat-app'" class="mt-4 mb-3">
          <div class="mb-4">
            <a :href="qrcodeUrl" class="btn btn-danger" style="font-size:18px" target="_blank"
              >前往{{ type === 'alipay-app' ? '支付宝' : '微信' }}完成充值</a
            >
          </div>
          <div>请点击按钮，前往充值页面, 完成支付后系统会自动到账</div>
        </div>
        <div class="row mt-5" v-else>
          <div class="col-5 d-flex justify-content-center">
            <div class="qrcode p-2">
              <img
                height="100%"
                width="100%"
                class="embed-responsive-item"
                :src="qrcodeUrl"
                v-if="!!qrcodeUrl"
                alt="qrcode"
              />
              <qrcode-border-svg />
            </div>
          </div>

          <div class="col-7 pr-0">
            <div class="mb-5 d-flex justify-content-center">
              <span class="" v-if="!!qrcodeUrl && !isSuccess">
                <br />扫一扫支付：<span class="text-12 text-primary">{{$currencySign}}{{ amount }}</span>
              </span>
              <span class="text-danger" v-if="isSuccess">支付成功 </span>
            </div>
            <template v-if="type == 'wechat' || type == 'alipay'">
              <div class="d-flex justify-content-between text-secondary">
                <div class="d-flex flex-column align-items-center justify-content-between" style="gap:10px">
                  <circle-svg text="1" />
                  <scan-svg />
                  <div>打开{{ type === 'wechat' ? '微信' : '支付宝' }}"扫一扫"</div>
                </div>
                <div class="d-flex flex-column align-items-center justify-content-between" style="gap:10px">
                  <circle-svg text="2" />
                  <qrcode-svg />
                  <div>扫描左侧二维码</div>
                </div>
                <div class="d-flex flex-column align-items-center justify-content-between" style="gap:10px">
                  <circle-svg text="3" />
                  <check-svg />
                  <div>确认金额并支付</div>
                </div>
              </div>
            </template>

            <template v-if="type == 'alipay-face'">
              请扫码前请详读注意事项，否则造成无法到账或其他经济损失由您个人承担。注意事项如下：<br /><br />

              1. 扫码后按提示输入相应的金额，否则无法到账。<br />
              2. 单次充值二维码仅可付款一次，多付或重复付款，网站概不负责。<br />
              3. 付款到账可能需要5-10分钟，如果未到账请查阅帮助。
            </template>
          </div>
        </div>
      </template>
    </div>

    <template slot="modal-footer">
      <ul class="text-muted small mt-4">
        <li>充值金额不会过期，但不会提现或转赠他人。</li>
        <li>
          付款到账可能需要5-10分钟! 未满十八（18）岁的个人不得使用本服务。
        </li>
      </ul>
    </template>
  </b-modal>
</template>

<script>
import nprogress from 'nprogress/nprogress'
import { mapGetters } from 'vuex'
import { AppService, UserService } from '@/services/api'
import { API_URL } from '@/config'
import RefSetting from './RefSetting'
import CircleSvg from './svgs/CircleSvg.vue'
import ScanSvg from './svgs/ScanSvg.vue'
import QrcodeBorderSvg from './svgs/QrcodeBorderSvg.vue'

export default {
  name: 'TopUp',
  components: {
    RefSetting,
    CircleSvg,
    ScanSvg,
    QrcodeBorderSvg,
  },
  data() {
    return {
      supported: process.env.VUE_APP_PAYMENT
        ? process.env.VUE_APP_PAYMENT.split(',')
        : ['alipay-face', 'alipay', 'wechat', 'cdk', 'alipay-app'],
      isSuccess: false,
      qrcodeUrl: null,
      cdkAmount: 0,
      generating: false,
      amount: 0,
      orderNo: null,
      cdk: null,
      mobile: localStorage.getItem('mobile') || '',
      alipayAccount: localStorage.getItem('alipayAccount') || '',
      wechatFast: [50, 100, 200, 250],
      fastCdkLink: [],
      cnyAmount: null,
      polling: null,
      referString: null,
      type: null,
      option: ['alipay', 'wechat'],
      agree: false,
    }
  },
  computed: {
    ...mapGetters(['modal', 'user', 'isMobile']),
    realNameVerified() {
      if (!this.user.info) return false
      return this.user.info.real_name_verified
    },
    modalVisible() {
      return this.modal === 'topup'
    },
    cdkDiscount() {
      if (this.user.info.cdk_discount) {
        return `${(parseFloat(this.user.info.cdk_discount) * 100).toFixed(2)}%`
      }
      return ''
    },
    bonus() {
      return parseFloat(this.user.info.cdk_discount) * 100
    },
  },
  async mounted() {
    const generateLinks = amounts =>
      amounts.map(parseFloat).map(amount => ({
        amount,
        link: `${API_URL}/cdk-link/${amount}`,
      }))
    this.fastCdkLink = generateLinks([3, 5, 10, 25, 50, 100, 200])
    // TODO:
    // const { amounts } = (await AppService.cdkLinks()).data
    // this.fastCdkLink = generateLinks(amounts)
  },
  methods: {
    toast(message, type = 'warning') {
      this.$bvToast.toast(message, {
        title: message,
        toaster: 'b-toaster-bottom-right',
        variant: type,
        solid: true,
      })
    },
    cdkOnClick() {
      this.type = 'cdk'
    },
    onHide() {
      nprogress.done()
      this.$store.commit('SET_MODAL', null)
      if (this.polling !== null) {
        clearInterval(this.polling)
      }
    },
    topupCdk() {
      if (!this.cdk || this.cdk.length < 16) return
      this.generating = true
      this.cdkAmount = 0
      nprogress.start()
      const { cdk, referString } = this
      UserService.topupCdk({ cdk, referString })
        .then(res => {
          nprogress.done()
          this.generating = false
          this.cdkAmount = res.data.amount
        })
        .catch(e => {
          this.generating = false
          this.$store.commit('SET_ERROR', '对不起，CDK无效')
          nprogress.done()
          console.log(e)
        })
    },
    onShow() {
      // this.cxka = false
      this.type = null
      this.amount = 0
      this.qrcodeUrl = null
      this.cnyAmount = null
      this.isSuccess = false
      if (this.polling !== null) {
        clearInterval(this.polling)
      }
    },
    topup() {
      if (!this.realNameVerified) {
        this.$bvModal.hide('topup')
        this.$modal.show('topup-real-name-modal')
        return
      }
      this.amount = parseFloat(this.amount)
      if (!this.type) {
        // this.type = localStorage.getItem('payment-type') || 'wechat'
        return
      }
      localStorage.setItem('payment-type', this.type)
      localStorage.setItem('alipayAccount', this.alipayAccount)
      localStorage.setItem('mobile', this.mobile)
      const { amount, referString } = this
      let { type } = this
      this.generating = true
      this.qrcodeUrl = null
      this.orderNo = null
      this.polling = null
      this.cnyAmount = null
      this.isSuccess = false
      const { mobile, alipayAccount } = this
      nprogress.start()
      // if (this.cxka) {
      //   type += '-cxka'
      // }
      const params =
        type !== 'alipay-face'
          ? { amount, type }
          : {
              amount,
              type,
              referString,
              mobile,
              alipayAccount,
            }
      // const topupApi = type == 'alipay-app' || type == 'wechat-app' ? UserService.topupV2 : UserService.topup
      UserService.topUp(params)
        .then(({ data }) => {
          this.qrcodeUrl = type == 'alipay-app' || type == 'wechat-app' ? data.link : data.url
          this.cnyAmount = data.cnyAmount
          this.generating = false
          this.orderNo = data.orderNo
          this.polling = setInterval(() => {
            if (this.orderNo) {
              UserService.topupStatus(this.orderNo).then(res => {
                if (res.data.status) {
                  this.isSuccess = true
                  this.toast('充值成功', 'success')
                  setTimeout(() => this.onHide(), 2000)
                }
              })
            }
          }, 8000)
        })
        .catch(e => {
          this.generating = false
          if (parseInt(e.response.status, 0) === 400) {
            this.$store.commit('SET_ERROR', {
              a: `/${this.lang || 'cn'}/inventory`,
              a_mask: '去绑定',
              text: '为保证平台充值安全，请在“库存-steam交易链接”中绑定steam账号，并确保账号可以交易',
            })
          } else if (parseInt(e.response.status, 0) === 429) {
            const sec = e.response.data.message - Math.round(Date.now() / 1000)
            this.$store.commit(
              'SET_ERROR',
              `为保障平台支付安全，您多次支付失败，将暂停您的充值服务，请 ${sec} 秒后再试。`
            )
          } else {
            this.$store.commit('SET_ERROR', e.response)
          }
          console.log(e)
        })
        .finally(() => {
          nprogress.done()
        })
    },
  },
}
</script>

<style lang="scss">
@import '../styles/style.scss';

#topup .modal {
  &-content {
    background-color: #141a24;
    border-radius: 10px;
    /* background: url('../assets/modal-bg.svg'), #141a24;
    background-size: cover; */
  }
  &-header {
    background: transparent;
    height: 60px;
    color: #fff;
    position: relative;
    border: none;
    .modal-title {
      width: 100px;
      text-align: center;
      position: absolute;
      top: 20px;
      left: calc(50% - 50px);
      font-size: 20px;
    }
    .close {
      color: #fff;
      position: absolute;
      right: 20px;
      &:focus {
        outline: none;
      }
    }
  }
  &-body {
    background: transparent;
    .topup {
      color: #fff;
      padding: 0 2em;
      font-size: 12px;
      /* transition: all ease 0.5s; */
      strong {
        display: block;
        width: 100%;
        text-align: left;
        cursor: pointer;
      }
      .select-type-main {
        display: block;
        width: 100%;
        text-align: left;
        color: rgba(255, 255, 255, 0.3);
      }
      .select-type {
        margin: 10px 0;
        .arrow {
          margin-right: 5px;
        }
      }

      &-select {
        margin: 1em 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        .select-row {
          width: 100%;

          .type-code {
            width: 100%;
            height: 80px;
            font-size: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            svg {
            }
            .badge-info {
              font-size: 12px;
              border-radius: 4px;
              background: #464b5f;
              font-weight: bold;
              color: #fff;
            }
            .bonus {
              font-size: 12px;
              padding: 4px;
              margin: 0;
              border-radius: 3px;
              background: #464b5f;
              color: #fff;

              &.hp {
                background: #ebc13d;
                color: #111;
              }
            }
          }
        }
        .separator {
          width: 100%;
          height: 1px;
          margin: 10px 0;
          background: rgba(255, 255, 255, 0.1);
        }
      }

      &-type {
        height: 72px;
        width: 100%;
        padding: 20px;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        font-size: 20px;
        transition: all ease 0.2s;
        border: none;
        line-height: 2.5rem;

        @media screen and (max-width: 500px) {
          width: 80px;
          height: 100px;
          margin: 10px;
          svg {
            font-size: 20px;
          }
        }
        svg {
          font-size: 30px;
        }
        .little {
          font-size: 12px;
          margin: 0;
          color: rgba(255, 255, 255, 0.5);
        }
        &-active,
        &:hover {
          background: #0b0e18;
        }
      }

      .cover {
        margin: 20px 0;
        display: flex;
        svg {
          font-size: 42px;
          margin: 0 15px 0 0;
        }
        div {
          display: flex;
          flex-direction: column;
          .type {
            font-size: 20px;
          }
          .desc {
            font-size: 12px;
            color: rgba($color: #fff, $alpha: 0.5);
          }
        }
      }

      .form-row {
        display: flex;
        flex-direction: column;

        .input-group-prepend {
          .input-group-text {
            background: rgba(102, 102, 102, 0.2);
            border: none;
          }
        }
      }
    }
  }
  &-footer {
    background: transparent;
    border: none;
    padding: 0 20px 32px;
  }
}
.topup-type {
  width: 100%;
  outline: 1px solid transparent;
}
.topup-1st {
  height: 180px;
}
.topup-alipay {
  height: 370px;
}
.topup-cdk {
  height: 240px;
}

.qrcode {
  border: solid 1px #333;
  position: relative;
  width: 190px;
  height: 190px;
  background: #fff;
}

.qrcode-border {
  position: absolute;
  top: -16px;
  left: -16px;
}

.no-link {
  text-decoration: none;
  color: #fff;
  &:hover {
    text-decoration: none;
    color: #fff;
  }
}
.cdk-item {
  width: 86px;
  margin: 5px;
  .btn {
    width: 80px;
    height: 40px;
    margin: 0.5em;
    padding: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: 1px solid $primary;

    &:focus,
    &:hover {
      color: #fff;
      box-shadow: rgba($color: $primary, $alpha: 0.8) 0 0 10px;
    }
    div {
      width: 80px;
      height: 100%;
      background: #191926;
      border-radius: 0.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.cnyAmount {
  padding-bottom: 10px;
  display: block;
  font-size: 1rem;
  span {
    color: #ebc13d;
  }
}
.form-control {
  border: none;
  background: rgba($color: #666, $alpha: 0.2);
  caret-color: #fff;
  color: #fff;
  &:focus {
    background: rgba($color: #666, $alpha: 0.3);
    color: #fff;
    box-shadow: none;
  }
  &:disabled {
    background: rgba($color: #666, $alpha: 0.6);
    color: #bbb;
  }
}

.agree-box {
  margin: 20px 0 10px;
  padding: 10px;
  display: flex;
  align-items: center;

  input {
    transform: scale(1.4);
  }

  label {
    margin: 2px 0 0 10px;
    font-size: 13px;
  }
}
</style>
