<template>
  <div class="collection-weapon">
    <div
      class="collection-weapon-wrapper"
      :class="{ hovering: isHover }"
      @mouseover="isHover = true"
      @mouseout="isHover = false"
      @click="emit('click')"
    >
      <div class="collection-weapon__image">
        <img :src="imageUrl" class="collection-weapon__img embed-responsive" />
      </div>

      <div class="collection-weapon__bg" :class="`background--${item.rarity ? item.rarity : 'grey'}`">
        <img src="@/assets/item-dot-bg.png" alt="" />
      </div>

      <div class="collection-weapon__details">
        <div class="d-flex justify-content-between align-items-center text-10">
          <span class="text-secondary">{{ nameObject.tag }} {{ nameObject.wear }}</span>
          <!-- <heart-svg class="ml-auto heart" @click.native.stop="clickLike" /> -->
          <!-- <span class="ml-2">{{ num }}</span> -->
        </div>
        <h5 class="mt-2 ellipsis" style="max-width:100%" ref="name" :class="{ oversize: oversize }">
          {{ nameObject.excludeWear }}
        </h5>
        <div class="">
          <span class="text-11"> {{ $currencySign }} {{ item.price }}</span>
          <!-- <span class="text-secondary ml-2">Floor price</span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nameFilter } from '@/utils'
import { ASSET_URL } from '@/config' 

export default {
  name: 'StoreItem',
  props: {
    item: Object,
    disabled: Boolean,
    dummy: Boolean,
  },
  data() {
    return {
      oversize: false,
      isHover: false,
      num: 23, 
    }
  },
  computed: {
    nameObject() {
      return nameFilter(this.item.name)
    },
    imageUrl() {
      return ASSET_URL + this.item.image_url
    },
  },
  mounted() {
    if (this.$refs.name && this.$refs.name.scrollWidth >= 240) {
      // this.oversize = true
    }
  },
  methods: {
    emit(event) {
      this.$emit(event)
    },
    clickLike() {
      this.num++
    },
  },
}
</script>

<style scoped lang="scss">
@import '../styles/style.scss';

@each $rarity, $radio in $radios-full {
  .background--#{$rarity} {
    background: $radio;
  }
}

@each $rarity, $color in $rarities {
  .background--#{$rarity} {
  }
}

.oversize {
  animation: toleft 5s linear 1s infinite alternate;
}
.buy {
  text-align: center;
  font-size: 1.2em;
  padding: 1.1em;
}
.collection-weapon {
  padding: 10px;
  position: relative;
  user-select: none;

  &-wrapper {
    width: 100%;
    height: 420px;
    overflow: hidden;
    position: relative;
    border-radius: 32px;
    background: #141a24;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.25);
    }

    &.hovering {
      .collection-weapon__bg {
        background: $primary-radio-full;
      }
      .collection-weapon__image {
        transform: scale(1.15);
      }
      .collection-weapon__info {
        .oversize {
          color: $primary;
        }
      }
    }
  }
  &__bg {
    width: 100%;
    height: 80%;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__img {
    &:hover {
      animation: bounce 4s ease-in-out 1s infinite alternate;
    }
    -webkit-user-drag: none;
    filter: drop-shadow(0 10px 3px rgba(0, 0, 0, 0.25));
  }
  &__image {
    height: 290px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5em;
    transition: 0.3s ease;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.collection-weapon__details {
  width: 100%;
  height: 30%;
  padding: 14px 22px;
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.1);
  user-select: none;
}

.heart {
  cursor: pointer;
  fill: #6b7280;
  &:hover {
    fill: rgb(255, 73, 82);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px) translateX(1px);
  }
  100% {
    transform: translateY(10px);
  }
}
@keyframes toleft {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(-30px);
  }
}
</style>
