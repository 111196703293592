<template>
  <div class="chip" :class="{ 'chip--solid': solid }" @click="$emit('click')">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Chip',
  props: {
    solid: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss" scoped>
@import '../styles/index.scss';
.chip {
  min-width: 85px;
  border: 1px solid #4b5563;
  padding: 12px 22px;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #7b8ba7;
  cursor: pointer;
  display: flex;
  align-items: center;

  &.chip--solid {
    background: #19202b;
    border: none;
  }

  &:hover:not(.chip--solid):not(.active),
  &:focus {
    border: 1px solid #5c6c87;
    box-shadow: none;
    outline: none;
  }

  &.active {
    border-color: $primary;
    background-color: #181818;
    color: #eee;
  }
}
</style>
