import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faSteam,
  faQq,
  faAlipay,
  faWeixin,
  faInstagram,
  faMedium,
  faDiscord,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'
import {
  faExchangeAlt,
  faLink,
  faInfoCircle,
  faShoppingCart,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faSortAmountUp,
  faSortAmountDownAlt,
  faYenSign,
  faPlus,
  faSignOutAlt,
  faKey,
  faSearch,
  faBoxes,
  faPaperclip,
  faReceipt,
  faCheck,
  faCheckCircle,
  faRedo,
  faDollarSign,
  faBox,
} from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle, faUser, faQuestionCircle, faSquare } from '@fortawesome/free-regular-svg-icons'

Vue.component('fa', FontAwesomeIcon)
Vue.component('FIcon', FontAwesomeIcon)

library.add(faSteam)
library.add(faExchangeAlt)
library.add(faLink)
library.add(faTimesCircle)
library.add(faSortAmountUp)
library.add(faSortAmountDownAlt)
library.add(faQq)
library.add(faInstagram)
library.add(faMedium)
library.add(faDiscord)
library.add(faTwitter)
library.add(faShoppingCart)
library.add(faInfoCircle)
library.add(faChevronRight)
library.add(faChevronLeft)
library.add(faChevronDown)
library.add(faChevronUp)
library.add(faYenSign)
library.add(faPlus)
library.add(faUser)
library.add(faQuestionCircle)
library.add(faSignOutAlt)
library.add(faAlipay)
library.add(faWeixin)
library.add(faKey)
library.add(faSearch)
library.add(faBoxes)
library.add(faReceipt)
library.add(faCheck)
library.add(faCheckCircle)
library.add(faRedo)
library.add(faSquare)
library.add(faDollarSign)
library.add(faBox)
