<template>
  <div class="chip" :class="[{ 'chip--solid': solid, active: active }, `chip--${size}`]" @click="$emit('click')">
    <div class="chip-wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Chip',
  props: {
    solid: { type: Boolean, default: false },
    active: Boolean,
    size: {
      type: String,
      default: 'md',
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/index.scss';

.chip {
  min-width: 140px;
  height: 56px;
  background: #253043;
  padding: 2px;
  box-sizing: border-box;
  border-radius: 35px;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #7b8ba7;
  cursor: pointer;
  transition: all 0.2s ease;

  &.chip--lg {
    height: 72px;
  }

  .chip-wrapper {
    background: #101520;
    width: 100%;
    height: 100%;
    padding: 14px 28px;
    margin: 0;
    border-radius: 35px;
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  &.chip--solid {
    background: #19202b;
    border: none;
  }

  &:hover:not(.chip--solid):not(.active),
  &:focus {
    background-color: #5c6c87;
    box-shadow: none;
    outline: none;
  }

  &.active {
    border: none;
    background: linear-gradient(to right, #ded221 0%, #32d229 100%);
    color: #eee;
  }
}
</style>
