<template>
  <button
    class="btn i-btn"
    v-bind="$attrs"
    v-on="$listeners"
    :class="[{ 'i-btn--rounded': rounded, 'i-btn--circular': circular }, `i-btn--${size}`]"
  >
    <b-spinner v-if="loading" small></b-spinner>
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Btn',
  props: {
    loading: { type: Boolean, default: false },
    rounded: { type: Boolean, default: false },
    circular: { type: Boolean, default: false },
    size: { type: String, default: 'md' },
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/index.scss';
.i-btn {
  height: 56px;
  line-height: 20px;
  padding: 0 26px;
  font-size: 14px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
}

.i-btn.i-btn--sm {
  height: 40px;
  border-radius: 4px;
}

.i-btn.i-btn--rounded {
  border-radius: 25px;
}

.i-btn.i-btn--circular {
  border-radius: 50%;
  height: 72px;
  width: 72px;
  padding: 12px;
}

.btn-primary.i-btn {
  background-color: $primary;
  &:focus {
    box-shadow: rgba($color: $primary, $alpha: 0.8) 0 0 10px;
  }
}
.btn-outline-primary.i-btn {
  color: #fff;
  background: rgba($color: $primary, $alpha: 0.2);
  &:focus {
    box-shadow: rgba($color: $primary, $alpha: 0.8) 0 0 10px;
  }
  &:hover {
    background: rgba($color: $primary, $alpha: 0.3);
  }
}

.btn-secondary {
  background: rgba(37, 48, 67, 1);
}

.btn-outline-danger:hover {
  background: rgba($color: $danger, $alpha: 0.2);
}

.btn-outline-info:hover {
  background: rgba($color: $info, $alpha: 0.2);
}

$active: #b8b8b888;
.btn-outline-secondary.i-btn {
  color: #ddd;
  border: 2px solid #253043;
  background-color: rgba(37, 48, 67, 0.2);
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);

  &:hover {
    outline: none;
    color: #fff;
    background-color: rgba(37, 48, 67, 0.2);
    border-color: $active;
  }

  &:focus {
    outline: none;
    color: #fff;
    background-color: rgba(37, 48, 67, 0.2);
    border-color: $active;
  }
  &:not(:disabled):not(:disabled):active {
    outline: none;
    color: #fff;
    background-color: rgba(37, 48, 67, 0.2);
    border-color: $active;
  }
}
</style>
