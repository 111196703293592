<template>
  <div class="footer container">
    <div class="d-flex">
      <div style="flex:0.5 0.5" class="pr-5">
        <b-navbar-brand href="/">
          <img src="@/assets/logo.svg" alt="" />
        </b-navbar-brand>
        <div class="pt-5">
          <h2 class="text-white bold">公平、自由、可靠的游戏装备交易平台</h2>
          <h5 class="text-secondary text-9 pt-2">版权所有 ©2022 SkinsKitty.com</h5>
          <div class="text-secondary mt-4">
            QQ客服：
            <a href="//wpa.qq.com/msgrd?v=1&amp;uin=982558358&amp;site=miaoka.la&amp;menu=yes" target="_blank">
              <b>
                <span class="text-secondary">982558358</span>
                <span> (点击咨询)</span>
              </b>
            </a>
            <p class="m-0 pt-2">电话：4007-189-311</p>
            <p class="m-0 pt-2">服务时间 :工作日上午10:00-下午22:00</p>
          </div>
        </div>
        <a
          v-if="hasD5D"
          class="text-secondary text-9 pt-2"
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
        >
          沪ICP备2023010408号-1
        </a>       
      </div>
      <div style="flex:0.166 0.166" class="text-white">
        <h5>交易</h5>
        <ul class="text-secondary">
          <li>
            <router-link to="/page/fees">收费标准</router-link>
          </li>
          <li>
            <router-link to="/page/faq">常见问题</router-link>
          </li>
        </ul>
      </div>
      <div style="flex:0.166 0.166" class="text-white">
        <h5>公司</h5>
        <ul class="text-secondary">
          <li>
            <router-link to="/page/overview">公司介绍</router-link>
          </li>
          <li>
            <router-link to="/page/contactus">联系我们</router-link>
          </li>
        </ul>
      </div>
      <div style="flex:0.166 0.166" class="text-white">
        <h5>法律法规</h5>
        <ul class="text-secondary">
          <li>
            <router-link to="/page/user_agreement">用户协议</router-link>
          </li>
          <li>
            <router-link to="/page/privacy_policy">隐私协议</router-link>
          </li>
          <li>
            <router-link to="/page/dispute">纠纷处理</router-link>
          </li>
        </ul>
      </div>
    </div>

    <divider />

    <div class="mt-4 text-secondary">
      <div class="text-center">
        <p>
          风险提示：饰品市场价格有波动，请理智消费。警惕网络诈骗，交易需谨慎。切勿利用本站参与网络违法行为。
        </p>
        <p>
          抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当 适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活
        </p>
      </div>
    </div>

    <divider v-if="isCxka" />

    <div class="mt-4 text-secondary" v-if="isCxka">
      <div class="text-center">
        <p class="">
          Copyright © 2016-2019 CXka.com All rights reserved. 沪ICP备18021571号-1
          <img src="@/assets/gongan.png" class="pl-2" />
          沪公安网备 31011502014407号 沪网文〔2017〕8088-597号
        </p>
        <p>
          ICP增值电信许可证：沪B2-20210402 EDI在线数据处理与交易许可证：沪B2-20210402
          <b class="text-11">版权所有 防盗版必究</b>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { STEAM_LOGIN_URL } from '@/services/config'
import { AppService } from '@/services/api'
import Divider from './Divider.vue'

export default {
  components: { Divider },
  name: 'Footer',
  data() {
    return {
      showMore: false,
      footerHTML: null,
    }
  },
  computed: {
    ...mapGetters(['user']),
    hasD5D() {
      return document?.location?.hostname?.includes('1bb2')
    },
    isCxka() {
      return window.location.origin.includes('csgo.cxka.com')
    },
  },
  async mounted() {
    // const { data } = await AppService.getPage('footer')
    // if (data) {
    //   this.footerHTML = data.content
    // }
  },
  methods: {
    login() {
      window.location = STEAM_LOGIN_URL
    },
    show() {
      this.showMore = true
    },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  margin-top: 150px;
  padding: 40px 0 120px;

  ul {
    list-style-type: none;
    padding: 0;

    li {
      padding: 10px 0;
    }
  }
}
</style>
