import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Homepage',
    redirect: { name: 'Home' },
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('@/views/User'),
    redirect: '/user/profile',
    meta: {
      loginRequired: true,
    },
    children: [
      {
        path: '/user/inventories',
        name: 'Inventories',
        component: () => import('@/views/User/Inventories'),
        meta: {
          loginRequired: true,
        },
      },
      {
        path: '/user/inventory/:id',
        name: 'InventoryDetail',
        component: () => import('@/views/User/InventoryDetail'),
        meta: {
          loginRequired: true,
        },
      },
      {
        path: '/user/profile',
        name: 'Profile',
        component: () => import('@/views/User/Profile'),
        meta: {
          loginRequired: true,
        },
      },
      {
        path: '/user/real-name-verify',
        name: 'RealNameVerify',
        component: () => import('@/views/User/RealName/RealNameVerify'),
        meta: {
          loginRequired: true,
        },
      },
    ],
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard'),
    meta: {
      loginRequired: true,
      isMerchant: true,
    },
  },
  {
    path: '/merchant/settings',
    name: 'MerchantSettings',
    component: () => import('@/views/Dashboard/Settings'),
    meta: {
      loginRequired: true,
      isMerchant: true,
    },
  },
  {
    path: '/page/:page',
    name: 'Page',
    component: () => import('@/views/Page'),
  },
  { path: '/home', name: 'Home', component: () => import('@/views/Home') },
  { path: '/marketplace', name: 'Marketplace', component: () => import('@/views/Marketplace') },
  { path: '/collections/:id', name: 'Collections', component: () => import('@/views/Collections') },
  { path: '/nft/:id', name: 'Nft', component: () => import('@/views/nft/NftDetail') },
  { path: '/store', name: 'Store', component: () => import('@/views/Store') },
  { path: '/search', name: 'SearchResult', component: () => import('@/views/SearchResult') },
  {
    path: '/store/:id',
    name: 'StoreDetail',
    component: () => import('@/views/Store/StoreDetail'),
  },
  {
    path: '/market',
    name: 'Market',
    component: () => import('@/views/Market'),
  },
  {
    path: '/market/:id',
    name: 'BuySell',
    component: () => import('@/views/Market/BuySell'),
  },
  {
    path: '/offer',
    name: 'Offer',
    component: () => import('@/views/Offer'),
    meta: {
      loginRequired: true,
    },
  },
  {
    path: '/offer/:id',
    name: 'OfferDetail',
    component: () => import('@/views/Offer/OfferDetail'),
    meta: {
      loginRequired: true,
    },
  },
  {
    path: '/offer/:tradeToken/new',
    name: 'NewOffer',
    component: () => import('@/views/Offer/NewOffer'),
    meta: {
      loginRequired: true,
    },
  },
  {
    path: '/offer/merchant/:tradeToken/new',
    name: 'NewOfferToMerchant',
    component: () => import('@/views/Offer/NewOffer'),
    meta: {
      loginRequired: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login'),
  },
  {
    path: '/bind',
    name: 'Bind',
    component: () => import('@/views/Bind'),
  },
  {
    path: '/*',
    name: 'NotFound',
    component: () => import('@/views/_404'),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach(async (to, from, next) => {
  console.log('to', to)
  if (to.meta.loginRequired) {
    if (!store.state.user.isLogin) {
      if (localStorage.getItem('jwt-token')) {
        await store.dispatch('LOAD_USER')
      }
      if (!store.state.user.isLogin) {
        next(`/login?redir=${to.fullPath}`)
      }
    }
  }
  if (to.meta.isMerchant) {
    if (store.state.user.info.is_merchant === undefined) {
      if (store.state.user.info.is_merchant) {
        next()
      } else {
        next('/')
      }
    }
    if (store.state.user.info.is_merchant) {
      next()
    } else {
      next('/')
    }
  }
  if (to.path === '/login' && store.state.user.isLogin) {
    next('/')
  }
  if (to.query.token) {
    store.dispatch('SET_LOGIN', to.query.token)
    next(to.path)
  } else {
    next()
  }
})

export default router
