<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12.5" r="11" fill="#7B8BA7" />
    <path
      v-if="text === '1'"
      d="M13.4425 8.77273V17.5H11.8615V10.3111H11.8104L9.76918 11.6151V10.1662L11.9382 8.77273H13.4425Z"
      fill="#141A24"
    />
    <path
      v-if="text === '2'"
      d="M9.04901 17.5V16.358L12.0788 13.3878C12.3686 13.0952 12.6101 12.8352 12.8033 12.608C12.9964 12.3807 13.1413 12.1605 13.2379 11.9474C13.3345 11.7344 13.3828 11.5071 13.3828 11.2656C13.3828 10.9901 13.3203 10.7543 13.1953 10.5582C13.0703 10.3594 12.8984 10.206 12.6797 10.098C12.4609 9.99006 12.2124 9.93608 11.9339 9.93608C11.647 9.93608 11.3956 9.99574 11.1797 10.1151C10.9638 10.2315 10.7962 10.3977 10.6768 10.6136C10.5604 10.8295 10.5021 11.0866 10.5021 11.3849H8.99787C8.99787 10.831 9.12429 10.3494 9.37713 9.94034C9.62997 9.53125 9.97798 9.21449 10.4212 8.99006C10.8672 8.76562 11.3786 8.65341 11.9553 8.65341C12.5405 8.65341 13.0547 8.76278 13.4979 8.98153C13.9411 9.20028 14.2848 9.5 14.5291 9.88068C14.7763 10.2614 14.8999 10.696 14.8999 11.1847C14.8999 11.5114 14.8374 11.8324 14.7124 12.1477C14.5874 12.4631 14.3672 12.8125 14.0518 13.196C13.7393 13.5795 13.3004 14.044 12.7351 14.5895L11.2308 16.1193V16.179H15.032V17.5H9.04901Z"
      fill="#141A24"
    />
    <path
      v-if="text === '3'"
      d="M11.9995 17.6193C11.3858 17.6193 10.8404 17.5142 10.3631 17.304C9.88867 17.0937 9.51367 16.8011 9.2381 16.4261C8.96254 16.0511 8.81623 15.6179 8.79918 15.1264H10.4015C10.4157 15.3622 10.4938 15.5682 10.6358 15.7443C10.7779 15.9176 10.9668 16.0526 11.2026 16.1491C11.4384 16.2457 11.7026 16.294 11.9952 16.294C12.3077 16.294 12.5847 16.2401 12.8262 16.1321C13.0676 16.0213 13.2566 15.8679 13.3929 15.6719C13.5293 15.4759 13.5961 15.25 13.5932 14.9943C13.5961 14.7301 13.5279 14.4972 13.3887 14.2955C13.2495 14.0937 13.0478 13.9361 12.7836 13.8224C12.5222 13.7088 12.2069 13.652 11.8375 13.652H11.0662V12.4332H11.8375C12.1415 12.4332 12.4071 12.3807 12.6344 12.2756C12.8645 12.1705 13.0449 12.0227 13.1756 11.8324C13.3063 11.6392 13.3702 11.4162 13.3674 11.1634C13.3702 10.9162 13.3148 10.7017 13.2012 10.5199C13.0904 10.3352 12.9327 10.1918 12.7282 10.0895C12.5265 9.98722 12.2892 9.93608 12.0165 9.93608C11.7495 9.93608 11.5023 9.98437 11.275 10.081C11.0478 10.1776 10.8645 10.3153 10.7253 10.4943C10.5861 10.6705 10.5123 10.8807 10.5037 11.125H8.98242C8.99379 10.6364 9.13441 10.2074 9.4043 9.83807C9.67702 9.46591 10.0407 9.17614 10.4952 8.96875C10.9498 8.75852 11.4597 8.65341 12.025 8.65341C12.6074 8.65341 13.1131 8.76278 13.5421 8.98153C13.9739 9.19744 14.3077 9.48864 14.5435 9.85511C14.7793 10.2216 14.8972 10.6264 14.8972 11.0696C14.9 11.5611 14.7551 11.973 14.4625 12.3054C14.1728 12.6378 13.7921 12.8551 13.3205 12.9574V13.0256C13.9341 13.1108 14.4043 13.3381 14.731 13.7074C15.0605 14.0739 15.2239 14.5298 15.2211 15.0753C15.2211 15.5639 15.0819 16.0014 14.8034 16.3878C14.5279 16.7713 14.1472 17.0724 13.6614 17.2912C13.1784 17.5099 12.6245 17.6193 11.9995 17.6193Z"
      fill="#141A24"
    />
  </svg>
</template>

<script>
export default {
  props: {
    text: { type: String, default: '1' },
  },
}
</script>
