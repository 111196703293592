import axios from 'axios'
import Vue from 'vue'
import VueAxios from 'vue-axios'

import { getToken, saveToken } from './jwt'
import { API_URL } from './config'

let apiUrl = API_URL
if (window.location.hostname === 'www.d5d.com') {
  apiUrl = 'https://api.d5d.com/api'
}

const ApiService = {
  init() {
    Vue.use(VueAxios, axios)

    const lang = localStorage.getItem('lang')

    Vue.axios.defaults.baseURL = apiUrl
    Vue.axios.interceptors.request.use(request => {
      if (getToken() != null) {
        // eslint-disable-next-line no-param-reassign
        request.headers.authorization = `Bearer ${getToken()}`
      }
      if (lang === 'en') {
        request.headers['Accept-Language'] = 'en'
      }
      return request
    })
    Vue.axios.interceptors.response.use(
      response => {
        const token = response.headers.authorization
        if (token) {
          saveToken(token)
        }
        // response.headers['Content-Language'] = 'en-US,en;'
        return response
      },
      error => {
        switch (error.response.status) {
          default:
            throw error
        }
      }
    )
  },

  query(resource, params) {
    return Vue.axios.get(resource, params)
  },

  get(path) {
    return Vue.axios.get(path)
  },

  post(path, params) {
    return Vue.axios.post(path, params)
  },

  update(path, params) {
    return Vue.axios.put(path, params)
  },

  put(path, params) {
    return Vue.axios.put(path, params)
  },
}

export default ApiService

// export const BoxService = {
//   getBoxesByType(type) {
//     return ApiService.get(`/boxes/type/${type}`)
//   },

//   getBoxById(id) {
//     return ApiService.get(`/boxes/${id}/items`)
//   },
//   getAllBoxes() {
//     return ApiService.get('/boxes')
//   },
//   getLiveDrop(best) {
//     return ApiService.get('/stat/live-drop' + (best ? '?treasure&' : ''))
//   },
// }

export const StoreService = {
  getStore(page = 1) {
    return ApiService.get(`/v1/store?page=${page}`)
  },
  buyStoreItem(marketHashName) {
    return ApiService.post(`/v1/store/${marketHashName}`)
  },
  getStoreByHashName(marketHashName) {
    return ApiService.get(`/v1/store/${marketHashName}`)
  },
}

export const OfferService = {
  getAllOffers() {
    return ApiService.get('/v1/offer')
  },
  getOfferById(id) {
    return ApiService.get(`/v1/offer/${id}/detail`)
  },
  createOffer(payload) {
    return ApiService.post('/v1/offer/create', { ...payload })
  },
  cancelOffer(offerId) {
    return ApiService.post(`/v1/offer/${offerId}/cancel`)
  },
  rejectOffer(offerId) {
    return ApiService.post(`/v1/offer/${offerId}/decline`)
  },
  acceptOffer(offerId) {
    return ApiService.post(`/v1/offer/${offerId}/accept`)
  },
  getRecentReceivers() {
    return ApiService.get('/v1/offer/recent')
  },
  createOfferToMerchant(payload) {
    return ApiService.post('/v1/merchant/send', { ...payload })
  },
  getMerchantById(id) {
    return ApiService.get(`/v1/merchant/${id}`)
  },
  getAllMerchants() {
    return ApiService.get(`/v1/merchant`)
  },
}

export const MarketService = {
  getAllItems() {
    return ApiService.get('/v1/market')
  },
}

export const UserService = {
  merchantBind(params) {
    return ApiService.post('/v1/merchant/bind', params)
  },
  getUserInfo() {
    return ApiService.get('/v1/user/me')
  },
  getInventories() {
    return ApiService.get('/v1/inventory')
  },
  getInventoryDetail(id) {
    return ApiService.get(`/v1/inventory/${id}/detail`)
  },
  getUserInventories(tradeUrl) {
    return ApiService.get(`/v1/user/inventories?trade_url=${tradeUrl}`)
  },
  recycleItems(ids) {
    return ApiService.post(`/v1/inventory/recycle`, { inventoryIds: ids })
  },
  withdrawItem(inventoryId) {
    return ApiService.post(`/v1/inventory/${inventoryId}/withdraw`)
  },
  saveSetting(payload) {
    return ApiService.put('/v1/user/setting', { ...payload })
  },
  getTransactions() {
    return ApiService.put('/v1/transactions')
  },
  generateTradeToken() {
    return ApiService.put('/v1/user/trade_url/regenerate')
  },
  refreshToken() {
    return ApiService.get('/v1/user/refresh')
  },
  topUp(payload) {
    return ApiService.post('/v1/topup', { ...payload })
  },
  getTransactionHistory() {
    return ApiService.get('/v1/user/payment/history')
  },
  alipayVerify(payload) {
    return ApiService.post('/v1/user/real-name-verify', { ...payload })
  },
  getVerificationResult(id) {
    return ApiService.get(`/v1/user/real-name-verify/${id}/result`)
  },
  getVerificationQrCode(id) {
    return ApiService.get(`/v1/user/real-name-verify/${id}`)
  },
}

export const AppService = {
  getPurchases() {
    return ApiService.get('/stats/purchase')
  },
  getPage(name) {
    return ApiService.get(`/v1/page/${name}`)
  },
  getNews() {
    return ApiService.get(`/v1/news`)
  },
  cdkLinks() {
    return ApiService.get('/cdk-links')
  },
  searchGlobal(keyword) {
    return ApiService.get(`/v1/search?keyword=${keyword}`)
  },
}

export const MerchantService = {
  getStat(page = 1) {
    return ApiService.get(`/v1/merchant/stats/offer?page=${page}`)
  },
  getAllStat() {
    return ApiService.get(`/v1/merchant/stats/total`)
  },
  saveSetting(payload) {
    // offer_accept_url，callback_url，fetch_merchant_user_url
    return ApiService.put('/v1/merchant/setting', { ...payload })
  },
  getWithdrawBalance() {
    return ApiService.get('/v1/withdraw/balance')
  },
  getWithdrawHistory() {
    return ApiService.get('/v1/withdraw/history')
  },
  withdraw(amount) {
    return ApiService.post('/v1/withdraw', { amount })
  },
}

export const getPropValue = (object, path = '') => path.split('.').reduce((o, x) => (o == undefined ? o : o[x]), object)
