import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { BootstrapVue } from 'bootstrap-vue'

import { Button, ButtonColorful } from '@/components/button'
import IconButton from '@/components/IconButton'
import Tabs from '@/components/Tabs'
import Input from '@/components/Input'
import SearchInput from '@/components/SearchInput'
import SearchInputNew from '@/components/new/SearchInputNew'
import FormInput from '@/components/FormInput'
import PageTitle from '@/components/PageTitle'
import CheckBox from '@/components/CheckBox'
import Chip from '@/components/Chip'
import Chip2 from '@/components/Chip2'
import ChipNew from '@/components/new/Chip'
import ChipSelect from '@/components/new/ChipSelect'
import SlideChipSelect from '@/components/new/SlideChipSelect'
import SlideChipSelectHorizontal from '@/components/new/SlideChipSelectHorizontal'
import DropDown from '@/components/dropdown-menu/DropDownNew'
import DropDownMultipleSelectNew from '@/components/dropdown-menu/DropDownMultipleSelectNew'
import DropDownBorderless from '@/components/dropdown-menu/DropDownCustomBorderless'
import DropDownMultipleSelect from '@/components/dropdown-menu/DropDownMultipleSelect'
import DropDownPriceRange from '@/components/dropdown-menu/DropDownPriceRange'
import Select from '@/components/new/Select'
import Divider from '@/components/Divider'
import TextDrawer from '@/components/TextDrawer'
import LongItem from '@/components/LongItem'
import Carousel from '@/components/Carousel'
import CarouselSlide from '@/components/Carousel/Slide'
import Avatar from '@/components/Avatar'
import CollectionCard from '@/components/CollectionCard'
import NftCardDetailed from '@/components/NftCardDetailed'
import CollectionWeapon from '@/components/CollectionWeapon'
import WeaponImg from '@/components/WeaponImg'
import Spinner from '@/components/new/Spinner'
import Loader from '@/components/new/Loader'

import Table from '@/components/new/Table'
import TabNew from '@/components/new/Tabs'
import SectionTitle from '@/components/new/SectionTitle'

import TickSvg from '@/components/svgs/TickSvg'
import CrossCircleSvg from '@/components/svgs/CrossCircleSvg'
import DoingSvg from '@/components/svgs/DoingSvg'
import ToDoSvg from '@/components/svgs/ToDoSvg'
import ErrorSvg from '@/components/svgs/ErrorSvg'
import ScanSvg from '@/components/svgs/ScanSvg'
import QrCodeSvg from '@/components/svgs/QrCodeSvg'
import CheckOutlineSvg from '@/components/svgs/CheckOutlineSvg'
import HeartSvg from '@/components/svgs/HeartSvg'
import HeartOutlineSvg from '@/components/svgs/HeartOutlineSvg'
import EthereumSvg from '@/components/svgs/EthereumSvg'
import WalletSvg from '@/components/svgs/WalletSvg'
import AllSvg from '@/components/svgs/AllSvg'
import AllSolidSvg from '@/components/svgs/AllSolidSvg'
import BlockChainSvg from '@/components/svgs/BlockChainSvg'
import DollarSvg from '@/components/svgs/DollarSvg'
import TickBareSvg from '@/components/svgs/TickBareSvg'
import CnSvg from '@/components/svgs/CnSvg'
import EnSvg from '@/components/svgs/EnSvg'
import TagSvg from '@/components/svgs/TagSvg'
import BagSvg from '@/components/svgs/BagSvg'
import TruckSvg from '@/components/svgs/TruckSvg'
import SortUpSvg from '@/components/svgs/SortUpSvg'
import SortDownSvg from '@/components/svgs/SortDownSvg'
import ChevronUpDownSvg from '@/components/svgs/ChevronUpDownSvg'
import ChevronRightCircleSvg from '@/components/svgs/ChevronRightCircleSvg'
import MeshSvg from '@/components/svgs/MeshSvg'
import MagnifierSvg from '@/components/svgs/MagnifierSvg'
import GearSvg from '@/components/svgs/GearSvg'
import MetaMaskSvg from '@/components/svgs/MetaMaskSvg'
import UserSvg from '@/components/svgs/UserSvg'
import QuestionSvg from '@/components/svgs/QuestionSvg'
import LogoutSvg from '@/components/svgs/LogoutSvg'
import AlipayLogoTextSvg from '@/components/svgs/AlipayLogoText'
import CheckGreenSvg from '@/components/svgs/CheckGreenSvg'
import CrossRedSvg from '@/components/svgs/CrossRedSvg'
import CrossSvg from '@/components/svgs/CrossSvg'
import BriefcaseSvg from '@/components/svgs/BriefcaseSvg'
import AdjustmentsSvg from '@/components/svgs/AdjustmentsSvg'
import RmbSvg from '@/components/svgs/RmbSvg'

import * as axios from 'axios'
import ApiService from './services/api'
import i18n from './i18n'

import '@/plugins'

import { ASSET_URL } from '@/config'
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/tooltip'
import Modal from '@/plugins/modal'
import ModalComp from '@/components/Modal'

import { CURRENCY_SIGN } from '@/shared'

Vue.prototype.$assetUrl = ASSET_URL
Vue.prototype.$currencySign = CURRENCY_SIGN

Vue.prototype.axios = axios
Vue.component('btn', Button)
Vue.component('btn-color', ButtonColorful)
Vue.component('icon-btn', IconButton)
Vue.component('tabs', Tabs)
Vue.component('i-input', Input)
Vue.component('search-input', SearchInput)
Vue.component('search-input-new', SearchInputNew)
Vue.component('form-input', FormInput)
Vue.component('page-title', PageTitle)
Vue.component('check-box', CheckBox)
Vue.component('chip', Chip)
Vue.component('chip2', Chip2)
Vue.component('chip-new', ChipNew)
Vue.component('chip-select', ChipSelect)
Vue.component('slide-chip-select', SlideChipSelect)
Vue.component('slide-chip-select-horizontal', SlideChipSelectHorizontal)
Vue.component('drop-down', DropDown)
Vue.component('drop-down-borderless', DropDownBorderless)
Vue.component('drop-down-multiple-select', DropDownMultipleSelect)
Vue.component('drop-down-multiple-select-new', DropDownMultipleSelectNew)
Vue.component('drop-down-price-range', DropDownPriceRange)
Vue.component('i-select', Select)
Vue.component('divider', Divider)
Vue.component('text-drawer', TextDrawer)
Vue.component('long-item', LongItem)
Vue.component('carousel', Carousel)
Vue.component('carousel-slide', CarouselSlide)
Vue.component('avatar', Avatar)
Vue.component('collection-card', CollectionCard)
Vue.component('nft-card-detailed', NftCardDetailed)
Vue.component('collection-weapon', CollectionWeapon)
Vue.component('weapon-img', WeaponImg)
Vue.component('spinner', Spinner)
Vue.component('loader', Loader)

Vue.component('my-table', Table)
Vue.component('modal', ModalComp)
Vue.component('tabs-new', TabNew)
Vue.component('section-title', SectionTitle)

Vue.component('tick-svg', TickSvg)
Vue.component('tickbare-svg', TickBareSvg)
Vue.component('cross-circle-svg', CrossCircleSvg)
Vue.component('cross-svg', CrossSvg)
Vue.component('doing-svg', DoingSvg)
Vue.component('todo-svg', ToDoSvg)
Vue.component('error-svg', ErrorSvg)
Vue.component('scan-svg', ScanSvg)
Vue.component('qrcode-svg', QrCodeSvg)
Vue.component('check-svg', CheckOutlineSvg)
Vue.component('heart-svg', HeartSvg)
Vue.component('heart-outline-svg', HeartOutlineSvg)
Vue.component('eth-svg', EthereumSvg)
Vue.component('wallet-svg', WalletSvg)
Vue.component('all-svg', AllSvg)
Vue.component('all-solid-svg', AllSolidSvg)
Vue.component('blockchain-svg', BlockChainSvg)
Vue.component('dollar-svg', DollarSvg)
Vue.component('cn-svg', CnSvg)
Vue.component('en-svg', EnSvg)
Vue.component('tag-svg', TagSvg)
Vue.component('bag-svg', BagSvg)
Vue.component('truck-svg', TruckSvg)
Vue.component('sort-down-svg', SortDownSvg)
Vue.component('sort-up-svg', SortUpSvg)
Vue.component('chevron-up-down-svg', ChevronUpDownSvg)
Vue.component('chevron-right-circle-svg', ChevronRightCircleSvg)
Vue.component('mesh-svg', MeshSvg)
Vue.component('magnifier-svg', MagnifierSvg)
Vue.component('gear-svg', GearSvg)
Vue.component('metamask-svg', MetaMaskSvg)
Vue.component('user-svg', UserSvg)
Vue.component('question-svg', QuestionSvg)
Vue.component('logout-svg', LogoutSvg)
Vue.component('alipay-logo-text-svg', AlipayLogoTextSvg)
Vue.component('check-green-svg', CheckGreenSvg)
Vue.component('cross-red-svg', CrossRedSvg)
Vue.component('briefcase-svg', BriefcaseSvg)
Vue.component('adjustments-svg', AdjustmentsSvg)
Vue.component('rmb-svg', RmbSvg)

Vue.component('v-chart', ECharts)

Vue.config.productionTip = false
ApiService.init()

Vue.use(Modal)
Vue.use(BootstrapVue)
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
