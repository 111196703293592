<template>
  <div class="nav-tabs-container mt-0">
    <div class="nav-tabs container pointer" :class="[`nav-tabs--${size}`, { 'nav-tabs--centered': center }]">
      <ul class="d-flex" style="width:100%;list-style:none">
        <li
          v-for="tab in options"
          :key="tab.value"
          class="tab"
          style="text-decoration:none"
          :class="{ active: isActive(tab.value) }"
          @click="iVal = tab"
        >
          <slot :name="tab.value" :item="tab" />

          <span v-if="!$scopedSlots[tab.value]">{{ tab.label }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Tabs',
  props: {
    value: Object,
    options: Array,
    size: { type: String, default: 'lg' },
    center: { type: Boolean, default: false },
  },
  data() {
    return {
      iVal: this.value,
    }
  },
  computed: {},
  watch: {
    value(val) {
      this.iVal = val
    },
    iVal(val) {
      this.$emit('input', val)
    },
  },
  methods: {
    isActive(value) {
      return this.iVal?.value === value
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/style.scss';

.nav-tabs-container {
  border-bottom: 1px solid #171d28;
}

.nav-tabs {
  border: none;
  display: flex;
  justify-content: space-between;
  height: 50px;

  &.nav-tabs--md {
    .tab {
      font-size: 13px;
      padding: 0 20px;
      min-height: 40px;
    }
  }

  &.nav-tabs--centered > ul {
    justify-content: center;
  }

  .tab {
    height: 50px;
    line-height: 50px;
    position: relative;
    padding: 0 30px;
    color: #5c6c87;
    font-size: 14px;
    font-weight: bold;

    &.active {
      color: #fff;
    }
    &:hover:not(.active) {
      color: #aaa;
    }
  }
}
</style>
