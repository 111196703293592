<template>
  <div class="text-drawer">
    <div class="text-secondary" :class="{ clamp: !expanded }">
      <p class="m-0">
        <slot />
      </p>
    </div>
    <btn v-if="expanded" class="text-primary px-0 py-1" @click="expanded = false">收起</btn>
    <btn v-else class="text-primary px-0 py-1" @click="expanded = true">更多</btn>
  </div>
</template>

<script>
export default {
  name: 'TextDrawer',
  data() {
    return {
      expanded: false,
    }
  },
}
</script>

<style lang="scss" scoped></style>
