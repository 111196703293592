<template>
  <div class="search-input" :style="`width:${width}`">
    <b-form-input
      v-model="keyword"
      class="keyword"
      :style="hideIcon ? '' : 'padding-right:32px'"
      type="text"
      required
      :placeholder="placeholder"
      :disabled="readOnly"
      :class="noRightBorder ? 'no-right-border' : ''"
    />
    <fa v-if="!hideIcon" class="search-icon" :icon="['fa', 'search']" />
    <b-spinner v-if="loading" class="search-input__spinner" variant="secondary" small />
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  props: {
    placeholder: String,
    value: String,
    width: { type: String, default: '300px' },
    readOnly: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    hideIcon: { type: Boolean, default: false },
    noRightBorder: { type: Boolean, default: false },
  },
  data() {
    return {
      keyword: this.value,
    }
  },
  watch: {
    value(val) {
      this.keyword = val
    },
    keyword(val) {
      this.$emit('input', val)
    },
  },
}
</script>

<style lang="scss" scoped>
$transit: all 0.15s ease-in-out;

.search-input {
  display: flex;
  position: relative;
  height: 40px;
}

.search-icon {
  color: #253043;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: $transit;
}

.dropdown {
  margin: 0;
}

.keyword {
  width: 100%;
  background-color: transparent;
  border-color: #253043;
  color: #7b8ba7;
  font-size: 0.9rem;
  line-height: 40px;
  height: 40px;
  padding-left: 20px;
  transition: $transit;

  &:hover,
  &:focus {
    border: 1px solid #5c6c87;
    background-color: rgba(92, 108, 135, 0.2);
    color: #5c6c87;
    box-shadow: none;
  }

  &:hover + .search-icon,
  &:focus + .search-icon {
    color: #5c6c87;
  }
}

.search-input__spinner {
  position: absolute;
  right: 10px;
  top: 10px;
}

.form-control.no-right-border {
  border-right: none;
  border-radius: 0.25rem 0 0 0.25rem;
}
</style>
