<template>
  <div class="search-input" :style="`width:${width}`">
    <b-form-input
      class="keyword"
      v-model="keyword"
      :type="type"
      required
      :placeholder="placeholder"
      :disabled="readOnly"
    ></b-form-input>
    <b-spinner
      v-if="loading"
      class="search-input__spinner"
      variant="secondary"
      small
    ></b-spinner>
  </div>
</template>

<script>
export default {
  name: 'FormInput',
  props: {
    placeholder: String,
    value: String,
    width: { type: String, default: '300px' },
    readOnly: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    type: { type: String, default: 'text' },
  },
  data() {
    return {
      keyword: this.value,
    }
  },
  watch: {
    value(val) {
      this.keyword = val
    },
    keyword(val) {
      this.$emit('input', val)
    },
  },
}
</script>

<style lang="scss" scoped>
.search-input {
  display: flex;
  position: relative;
  height: 38px;
}

.dropdown {
  margin: 0;
}

.keyword {
  width: 100%;
  background-color: #25304333;
  border-color: transparent;
  color: #7b8ba7;
}

.search-input__spinner {
  position: absolute;
  right: 10px;
  top: 10px;
}

.form-control.no-right-border {
  border-right: none;
  border-radius: 0.25rem 0 0 0.25rem;
}
</style>
