<template>
  <div class="checkbox" @click="handleClick">
    <div class="checkbox-input" :class="{ checked: value }">
      <FIcon v-if="value" class="checkmark" :icon="['fa', 'check']" />
    </div>
    <input v-model="value" class="input" type="checkbox" />
    <div v-if="$slots.default" class="px-1" />
    <slot name="default" />
  </div>
</template>

<script>
export default {
  name: 'CheckBox',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      state: false,
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    handleClick() {
      const val = !this.value
      this.$emit('input', val)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../styles/index.scss';

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  .input {
    display: none;
  }
}
.checkbox-input {
  width: 18px;
  height: 18px;
  background: transparent;
  border: 1px solid #7b8ba7;
  position: relative;
  border-radius: 4px;

  &.checked {
  }
}

.checkmark {
  font-size: 20px;
  color: rgb(172, 255, 48);
  position: absolute;
  top: -3px;
  right: -3px;
}
</style>
