<template>
  <div>
    <transition name="overlay">
      <div v-if="visible" class="window-overlay" key="overlay" @click="hide"></div>
    </transition>
    <div v-if="visible" class="window" key="window" tabindex="-1">
      <div class="window__inner">
        <transition name="modal">
          <div class="window__block" :class="[{}]">
            <div
              class="window__modal-inner"
              :class="[
                `rounded-${rounded}`,
                {
                  'window__modal-no-dialog': noDialog,
                  'bg-white': variant === 'light',
                  'text-black': variant === 'light',
                  'text-white': variant !== 'light',
                },
              ]"
              :style="[
                {
                  width,
                  background: bg,
                  padding,
                },
              ]"
            >
              <slot />
              <button v-if="!hideClose" class="window__close" @click="$modal.hide(id)">×</button>

              <div
                class="window__header"
                :class="[
                  {
                    'bg-white': variant === 'light',
                    'text-[#4B5563]': variant === 'light',
                  },
                ]"
                v-if="!hideHeader && !noDialog"
              >
                <div class="window__title" v-if="!$scopedSlots.header">
                  {{ title }}
                </div>
                <slot name="header" />
              </div>

              <div
                v-if="!noDialog"
                class="window__content"
                :style="[
                  {
                    height,
                    maxHeight,
                  },
                ]"
              >
                <slot name="body" />
              </div>

              <div class="window__footer" v-if="!hideFooter && !noDialog">
                <slot name="footer" />
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/plugins/modal'

export default {
  name: 'Modal',
  props: {
    id: String,
    title: String,
    width: { type: String, default: '750px' },
    height: { type: String, default: '' },
    maxHeight: { type: String, default: '500px' },
    rounded: { type: String, default: 'md' },
    hideHeader: Boolean,
    hideFooter: Boolean,
    hideClose: Boolean,
    noDialog: Boolean,
    bg: { type: String, default: '' },
    padding: { type: String, default: '0' },
    variant: { type: String, default: 'dark' },
  },
  data() {
    return {
      visible: false,
    }
  },
  beforeMount() {
    Modal.EventBus.$on('show', id => {
      if (id === this.id) {
        this.show()
      }
    })
    Modal.EventBus.$on('hide', id => {
      if (id === this.id || id === 'all') {
        this.hide()
      }
    })
  },
  mounted() {},
  beforeDestroy() {
    const body = document.querySelector('body')
    body.classList.remove('overflow-hidden')
    // body.style.paddingRight = 'unset'
  },
  methods: {
    show() {
      // this.toggleOverlay()
      this.visible = true
      this.$emit('show')
      const body = document.querySelector('body')
      body.classList.add('overflow-hidden')
      // body.style.paddingRight = '7px'
      document.addEventListener('keydown', e => {
        if (e.keyCode === 27) {
          this.hide()
        }
      })
    },
    hide() {
      // this.toggleOverlay()
      this.visible = false
      this.$emit('hide')
      const body = document.querySelector('body')
      body.classList.remove('overflow-hidden')
      // body.style.paddingRight = 'unset'
    },
    toggleOverlay() {
      const existing = document.querySelector('#window-overlay')
      if (existing) {
        document.querySelector('#app').removeChild(existing)
      } else {
        var div = document.createElement('div')
        div.id = 'window-overlay'
        div.classList.add('window-overlay')
        document.querySelector('#app').appendChild(div)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.overlay-enter-active,
.overlay-leave-active {
  opacity: 1;
  transition: opacity 0.2s ease;
}
.overlay-enter,
.overlay-leave-to {
  opacity: 0;
}

.modal-enter-active,
.modal-leave-active {
  transform: scale(1);
  opacity: 1;
  transition: all 0.2s ease;
}
.modal-enter,
.modal-leave-to {
  transform: scale(0.95);
  opacity: 0;
}

.window {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: auto;
  outline: none;
  -webkit-overflow-scrolling: touch;
  animation-duration: 300ms;
}

.window-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  transform: translateZ(0);
  transition: background-color 0.2s ease;
}
.window__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  padding: 25px 50px 25px 20px;
  transform: translate(0);
}

.window__block {
  display: flex;
  justify-content: center;
  max-width: 100%;
  /* will-change: transform, opacity; */
  animation-duration: 300ms;
}

.window__modal-inner {
  position: relative;
  width: 750px;
  background-color: #141a24;

  &.window__modal-no-dialog {
    background-color: transparent;
  }
}

.window__header {
  padding: 33px 39px 20px;
  /* background-color: #141a24; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
  font-weight: 600;
}

.window__title {
  position: relative;
  padding-left: 0;
  color: #fff;
  font-weight: 900;
  font-size: 21px;
  text-align: left;
}

.window__close {
  position: absolute;
  top: 7px;
  right: 8px;
  z-index: 5;
  width: 30px;
  color: #5f6277;
  font-size: 32px;
  text-align: center;
  transition: color 0.3s ease;
  background-color: transparent;
  border: none;
}

.window__content {
  overflow: auto;
}

.window__footer {
  padding-bottom: 20px;
}
</style>
