import { SET_ERROR, SET_USER, SET_NOT_LOGIN, SET_LOGIN, SET_MODAL, SET_NUM_OFFER } from './types'

export default {
  [SET_USER](state, payload) {
    state.user.info = payload
    state.user.isLogin = true
  },

  ['SET_USER_LOADING'](state, payload) {
    state.userLoading = payload
  },

  [SET_NOT_LOGIN](state) {
    state.user.info = {}
    state.user.isLogin = false
  },

  [SET_LOGIN](state) {
    state.user.isLogin = true
  },

  [SET_ERROR](state, payload) {
    state.error = payload
  },

  [SET_MODAL](state, payload) {
    state.modal = payload
  },

  [SET_NUM_OFFER](state, payload) {
    state.numOfOffers = payload
  },

  ['SET_ALL_OFFERS'](state, payload, a) {
    state.allOffers = payload
    const num = payload.filter(i => i.status === 10).length
    this.commit('SET_NUM_OFFER', num)
  },

  ['SET_RECENT_RECEIVERS'](state, payload) {
    state.recentReceivers = payload
  },

  ['SET_ADDRESS'](state, payload) {
    state.wallet.address = payload
  },

  ['SET_BALANCE'](state, payload) {
    state.wallet.balance = payload
  },

  ['SET_LANG'](state, payload) {
    state.lang = payload
  },
}
