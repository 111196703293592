<template>
  <div class="nft-card">
    <router-link to="/nft/123">
      <div class="wrapper">
        <div class="img" :style="{ backgroundImage: `url(${item.image_url})` }"></div>
        <div class="nft-card__details">
          <div class="d-flex justify-content-between align-items-center">
            <span class="text-secondary">{{ item.name }}</span>
            <!-- <span class="ml-auto">{{ num }}</span>
            <heart-svg class="ml-2 heart" @click.native="clickLike" /> -->
          </div>
          <h5 class="mt-2 ellipsis" style="max-width:100%">{{ item.title }}</h5>
          <div class="">
            <span class="text-12"> $ {{ item.price }}</span>
            <span class="text-secondary ml-2">Floor price</span>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'NftCard',
  props: {
    item: Object,
  },
  data() {
    return {
      num: 23,
    }
  },
  methods: {
    clickLike() {
      this.num++
    },
  },
}
</script>

<style lang="scss" scoped>
.nft-card {
  height: 434px;
  padding: 10px;

  .wrapper {
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 24px;
    overflow: hidden;
    position: relative;
    background: #1f2430;
  }

  .img {
    width: 100%;
    height: 70%;
    border-radius: 24px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.nft-card__details {
  width: 100%;
  height: 30%;
  padding: 14px 22px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #1f2430;
  color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.1);
  user-select: none;
}

.heart {
  cursor: pointer;
  fill: #6b7280;
  &:hover {
    fill: rgb(255, 73, 82);
  }
}
</style>
