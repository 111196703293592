var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"VueCarousel",class:{ 'VueCarousel--reverse': _vm.paginationPosition === 'top' }},[_c('div',{ref:"VueCarousel-wrapper",staticClass:"VueCarousel-wrapper"},[_c('div',{ref:"VueCarousel-inner",class:['VueCarousel-inner', { 'VueCarousel-inner--center': _vm.isCenterModeEnabled }],style:({
        transform: ("translate(" + _vm.currentOffset + "px, 0)"),
        transition: _vm.dragging ? 'none' : _vm.transitionStyle,
        'ms-flex-preferred-size': (_vm.slideWidth + "px"),
        'webkit-flex-basis': (_vm.slideWidth + "px"),
        'flex-basis': (_vm.slideWidth + "px"),
        visibility: _vm.slideWidth ? 'visible' : 'hidden',
        height: ("" + _vm.currentHeight),
        'padding-left': (_vm.padding + "px"),
        'padding-right': (_vm.padding + "px"),
      })},[_vm._t("default")],2)]),(_vm.navigationEnabled)?_vm._t("navigation",function(){return [(_vm.isNavigationRequired)?_c('navigation',{attrs:{"clickTargetSize":_vm.navigationClickTargetSize,"nextLabel":_vm.navigationNextLabel,"prevLabel":_vm.navigationPrevLabel},on:{"navigationclick":_vm.handleNavigation}}):_vm._e()]}):_vm._e(),(_vm.paginationEnabled)?_vm._t("pagination",function(){return [_c('pagination',{on:{"paginationclick":function($event){return _vm.goToPage($event, 'pagination')}}})]}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }