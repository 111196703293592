<template>
  <div class="app">
    <navbar />
    <topup-real-name-modal />
    <!-- <history v-if="user.isLogin"></history> -->
    <transition name="fade" mode="out-in">
      <router-view class="app-main" />
    </transition>
    <iFooter />
    <top-up />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Navbar from '@/components/Navbar'
import iFooter from '@/components/Footer'
import { OfferService } from './services/api'
import TopUp from './components/TopUp.vue'
import TopupRealNameModal from '@/views/User/RealName/TopupRealNameModal'

export default {
  name: 'App',
  components: {
    Navbar,
    iFooter,
    TopUp,
    TopupRealNameModal,
  },
  computed: {
    ...mapGetters(['user']),
  },
  created() {
    if (this.user.isLogin) {
      OfferService.getAllOffers()
        .then(({ data }) => {
          this.$store.commit('SET_ALL_OFFERS', data)
        })
        .catch(() => {})
        .finally(() => {})
    }
  },
  mounted() {
    this.$store.dispatch('LOAD_NUM_OFFER')
  },
  watch: {
    $route(to) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style lang="scss">
@import './styles/index.scss';
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/src/index.scss';
@import './styles/fonts.scss';
@import './fonts/TTMussels-Regular/style.css';
@import './styles/bootstrap-modify.scss';

.app {
  /* background-image: url('./assets/wide-bg.svg'); */
  background-repeat: no-repeat;
  background-size: contain;
}

.app-main {
  min-height: 800px;
}

body {
  background: #0b0e18;

  &.overflow-hidden {
    padding-right: 5px;
  }
}

.b-skeleton {
  z-index: 100;
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  background: rgba($color: #141a24, $alpha: 0.8);
  top: 0;
  left: 0;
}
.b-skeleton::after {
  background: linear-gradient(90deg, transparent, #2b374d20, transparent);
}

.page-link {
  background-color: transparent;
  border-color: transparent;
}
.page-item.disabled .page-link {
  background-color: transparent;
  border-color: transparent;
}
.page-link:hover {
  background: rgba(37, 48, 67, 0.2);
  border: 1px solid #253043;
}
.page-item.active .page-link {
  background: rgba(37, 48, 67, 0.2);
  border: 1px solid #253043;
}
.page-link:focus {
  box-shadow: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.11415926s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.b-toast {
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0px 18px 27px rgba(23, 12, 12, 0.57);

  .toast {
    border-bottom: 2px solid #bb3333;

    .toast-body {
      display: none;
      color: #bb3333;
      background: linear-gradient(0deg, rgba(187, 51, 51, 0.2) 0%, rgba(187, 51, 51, 0) 50%), #0c0f17;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;

      .toast-wrapper {
        display: flex;

        .toast-text {
          line-height: 24px;
        }
      }
    }
    .toast-header {
      padding: 10px 15px 10px 45px;
      height: auto;
      color: #fff;
      background: linear-gradient(0deg, rgba(187, 51, 51, 0.2) 0%, rgba(187, 51, 51, 0) 50%), #0c0f17;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      border: none;

      .toast-wrapper {
        .toast-heading {
          display: flex;
          align-items: center;

          .toast-title {
            font-size: 16px;
          }
        }

        .toast-text {
          line-height: 24px;
        }
      }
      .close {
        color: #fff;
        font-weight: normal;
      }
    }
  }
}
.b-toast.b-toast-success {
  .toast {
    border: none;
    border-bottom: 2px solid #6aa54f;

    .toast-header {
      color: #6aa54f;
      background: linear-gradient(0deg, rgba(106, 165, 79, 0.2) 0%, rgba(106, 165, 79, 0) 50%), #0c0f17;
      &::before {
        content: '';
        width: 30px;
        height: 25px;
        background: url('./assets/toast/tick.svg');
        background-repeat: no-repeat;
        position: absolute;
        left: 10px;
      }
    }
  }
}
.b-toast.b-toast-warning,
.b-toast.b-toast-danger {
  .toast {
    color: #bb3333;
    border: none;
    border-bottom: 2px solid #bb3333;

    .toast-header {
      background: linear-gradient(0deg, rgba(187, 51, 51, 0.2) 0%, rgba(187, 51, 51, 0) 50%), #0c0f17;
      &::before {
        content: '';
        width: 30px;
        height: 25px;
        background: url('./assets/toast/warning.svg');
        background-repeat: no-repeat;
        position: absolute;
        left: 10px;
      }
    }
  }
}

.b-toast-danger {
  border-bottom: 2px solid #c75d67;
  background: linear-gradient(#0b0e17 50%, #21141d);
}

.modal-content {
  background-color: #141a24;
}

.modal-header {
  border: none;
  background: transparent;

  button.close {
    color: white;
  }
}

.modal-body {
  color: #fff;
  border: none;
  background: transparent;

  .btn {
    margin: 0 5px;
    color: #fff;
    border: none;
    &-recycle {
      color: #fff;
      font-size: 15px;
      height: 40px;
      background: transparent;
      border: 1px solid #6aa54f;
      border-radius: 5px;
      position: absolute;
      right: 20px;
    }
    &-info {
      border: none;
    }
  }
  .form-control {
    outline: 1px solid transparent;
    &:focus {
      outline: none;
    }
  }
}

.modal-footer {
  border: none;
  background: transparent;
}
</style>
