<template>
  <div class="avatar" :class="[{ 'avatar--rounded': rounded }]" :style="{ width: size, height: size }">
    <img :src="imgUrl" alt="" />
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    imgUrl: String,
    rounded: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '36px',
    },
  },
}
</script>

<style lang="scss" scoped>
.avatar {
  overflow: hidden;

  &:not(.avatar--rounded) {
    border-radius: 50%;
  }

  &.avatar--rounded {
    border-radius: 12px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
