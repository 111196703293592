<template>
  <section class="table">
    <div class="d-flex py-3 mb-4 align-items-center justify-content-between">
      <small class="text-secondary">共 {{ data.length }} 条记录</small>

      <b-pagination
        v-model="currentPage"
        class="m-0"
        :total-rows="total"
        :per-page="perPage"
        first-number
        last-number
      />
    </div>

    <div class="table__header">
      <div
        class="table__col"
        :class="{ fixed: col.fixed, sortable: col.sortable }"
        :style="`flex-basis:${col.fixed}px`"
        v-for="(col, k) in cols"
        :key="`table-header-${k}`"
      >
        <span v-if="col.sortable" class="pointer" @click="sortByCol(col)">
          <span>{{ col.label }}</span>
          <chevron-up-down-svg />
        </span>
        <span v-else>{{ col.label }}</span>
      </div>
    </div>

    <div class="table__body" v-if="loading">
      <b-skeleton v-for="i in 5" :key="i" width="100%" height="72px" class="m-0 my-3"></b-skeleton>
    </div>

    <div class="table__body" v-if="!loading && data && data.length === 0">
      <div
        class="d-flex justify-content-center align-items-center text-secondary mt-2"
        style="width: 100%; height: 400px;background:rgba(37, 48, 67, 0.2)"
      >
        没有物品哦~
      </div>
    </div>

    <div class="table__body" v-if="!loading && !link">
      <div class="table__body__entry" v-for="(item, k) in sortedData" :key="`table-entry-${k}`">
        <div
          class="table__col"
          :class="{ fixed: col.fixed }"
          :style="`flex-basis:${col.fixed}px`"
          v-for="(col, k) in cols"
          :key="k"
        >
          <slot :name="col.value" :item="item" :value="item[col.value]" :col="col" />

          <span v-if="!$scopedSlots[col.value]">{{ item[col.value] }}</span>
        </div>
      </div>
    </div>

    <div class="table__body" v-if="!loading && link">
      <router-link class="table__body__entry" v-for="(item, k) in sortedData" :key="`table-entry-${k}`" :to="link">
        <div
          class="table__col"
          :class="{ fixed: col.fixed }"
          :style="`flex-basis:${col.fixed}px`"
          v-for="(col, k) in cols"
          :key="k"
        >
          <slot :name="col.value" :item="item" :value="item[col.value]" :col="col" />

          <span v-if="!$scopedSlots[col.value]">{{ item[col.value] }}</span>
        </div>
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Table',
  props: {
    data: Array,
    loading: Boolean,
    cols: Array,
    link: { type: String, default: '' },
    perPage: { type: Number, default: 10 },
    total: Number,
  },
  data() {
    return {
      sortCol: '',
      sortUp: false,
      currentPage: 1,
    }
  },
  computed: {
    sortedData() {
      let rv = this.data
      if (this.sortCol) {
        rv = rv.sort((a, b) => {
          const float_a = parseFloat(a[this.sortCol])
          const float_b = parseFloat(b[this.sortCol])
          if (this.sortUp) {
            return float_a - float_b
          } else {
            return float_b - float_a
          }
        })
      }

      return rv
    },
  },
  mounted() {},
  watch: {
    currentPage(v) {
      this.$emit('pageChange', v)
    },
  },
  methods: {
    sortByCol(col) {
      if (this.sortCol === col.value) {
        this.sortUp = !this.sortUp
      } else {
        this.sortCol = col.value
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/style.scss';

.table {
  width: 100%;
}
.table__header {
  width: 100%;
  height: 72px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  color: $secondary;
  border: 2px solid rgba($color: $secondary, $alpha: 0.1);
  font-size: 15px;
  border-radius: 10px;
}

.table__col {
  flex: 1 20%;
  padding: 0 10px;

  &.fixed {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100px;
  }

  .pointer {
    &:hover {
      color: #fff;
    }
  }
}

.table__body {
  &__entry {
    height: 72px;
    margin-top: 16px;
    padding: 20px;
    background: #141a24;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    border-radius: 10px;
  }
}

.table__col__img {
  width: 150px;
  height: 120px;
  padding: 5px 10px;
  background-color: #1c2028;

  .img {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.table__col__name {
  padding: 20px;
  flex: 1;

  .name {
  }
  .type {
    margin-top: 10px;
    font-size: 12px;
    line-height: 15px;
    color: #7b8ba7;
    -webkit-line-clamp: 1;
  }
  .wear {
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    opacity: 0.6;
    -webkit-line-clamp: 1;
  }
}
</style>
