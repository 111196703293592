export default function nameFilter(longName) {
  if (!longName) {
    return {
      type: '',
      name: '',
      tag: '',
      wear: '',
    }
  }
  const nameArray = longName.split('|')

  const type =
    nameArray.length > 1
      ? nameArray.filter((x, i) => i < nameArray.length - 1).join('|')
      : longName
  const name = nameArray.length > 1 ? nameArray[nameArray.length - 1] : ''

  const regex = /（([^)]+)）/g

  const tag = type.match(regex) ? type.match(regex)[0] : ''
  const cleanTag = tag
    ? tag
      .replace('（', '')
      .replace('）', '')
      .replace('★', '')
    : ''
  const cleanType = tag ? type.replace(tag, '') : type

  const regex2 = /\(([^)]+)\)/g

  const wear = name.match(regex2) ? name.match(regex2)[0] : ''
  const excludeWear = longName.replace(wear, '')
  const cleanWear = wear ? wear.replace('(', '').replace(')', '') : ''
  const cleanName = wear ? name.replace(wear, '') : name

  return {
    type: cleanType,
    name: cleanName,
    tag: cleanTag,
    wear: cleanWear,
    excludeWear: excludeWear,
  }
}
