export function getSingleFromAccumulative(arr) {
  if (!arr) return null
  let rv = []
  for (let i = 0; i < arr.length; i++) {
    if (i === 0) {
      rv.push(arr[i])
    } else {
      rv.push((arr[i] - arr[i - 1]).toFixed(2))
    }
  }
  return rv
}
