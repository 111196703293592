import Vuex from 'vuex'
import Vue from 'vue'

import getters from './getters'
import actions from './actions'
import mutations from './mutations'

Vue.use(Vuex)

function getLang() {
  let rv = localStorage.getItem('lang')
  if (!rv || (rv !== 'cn' && rv !== 'en')) {
    rv = 'cn'
  }
  return rv
}

export default new Vuex.Store({
  state: {
    app: {
      news: '',
      warning: '',
    },
    box: {},
    user: {
      isLogin: false,
      info: {},
    },
    userLoading: false,
    modal: '',
    loading: false,
    error: {},
    numOfOffers: 0,
    allOffers: [],
    recentReceivers: [],
    wallet: {
      address: '',
      balance: '',
    },
    lang: getLang(),
  },
  getters,
  actions,
  mutations,
  strict: true,
  plugins: [],
})
